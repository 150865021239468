import { ListFormComponent } from '@shared/models/list-form-component';
import { ColoredSelectButtonOptions } from '@shared/options';

export class ColoredSelectButton extends ListFormComponent {
  elementTypeName: string = 'ColoredSelectButton';

  options: Array<{ label: string; value: any; color: string }>;

  multiselect: boolean;

  constructor(options: ColoredSelectButtonOptions) {
    super(options);
    this.multiselect = options.multiselect;
    this.horizontal = true;
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputSwitch',
          name: 'multiselect',
          label: 'Multi Select?',
          required: false,
          default: false,
        },
        {
          elementType: 'RepeatableContainer',
          name: 'options',
          colspan: 12,
          label: 'New Buttons',
          addButtonLabel: 'Add New Option',
          children: [
            {
              elementType: 'InputText',
              name: 'label',
              label: 'Label',
              pKeyFilter: 'alphanum',
              required: true,
              colspan: 4,
            },
            {
              elementType: 'InputText',
              name: 'value',
              label: 'Value',
              pKeyFilter: 'alphanum',
              required: true,
              colspan: 4,
            },
            {
              elementType: 'Dropdown',
              name: 'color',
              colspan: 4,
              required: false,
              label: 'Color',
              options: [
                { label: 'Red', value: 'red' },
                { label: 'Yellow', value: 'yellow' },
                { label: 'Green', value: 'green' },
                { label: 'Blue', value: 'blue' },
              ],
              horizontal: null,
              filter: true,
              filterBy: 'label',
              placeholder: 'Select a color',
            },
          ],
          subscribeActions: [
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceShow',
              type: 'TOGGLE_OFF',
            },
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceHide',
              type: 'TOGGLE_ON',
            },
          ],
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      multiselect: this.multiselect,
    });
  }
}
