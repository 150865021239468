// models
import { ListFormComponent } from '@shared/models/list-form-component';
import { DropdownOptions } from '@shared/options';

export class Dropdown extends ListFormComponent {
  elementTypeName: string = 'Dropdown';

  options: Array<{ label: string; value: any }>;

  filter: boolean;
  filterBy: string;

  placeholder: string;
  showClear: boolean;

  constructor(options: DropdownOptions) {
    super(options);
    this.filter = options.filter;
    this.filterBy = options.filterBy || 'label';
    this.placeholder = options.placeholder;
    this.showClear = options.showClear;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      filter: this.filter,
      filterBy: this.filterBy,
      placeholder: this.placeholder,
      showClear: this.showClear,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'RepeatableContainer',
          name: 'options',
          colspan: 12,
          label: 'New Repeatable Container',
          addButtonLabel: 'Add New Option',
          children: [
            {
              elementType: 'InputText',
              name: 'label',
              label: 'Label',
              pKeyFilter: 'alphanum',
              required: true,
              colspan: 4,
            },
            {
              elementType: 'InputText',
              name: 'value',
              label: 'Value',
              pKeyFilter: 'alphanum',
              required: true,
              colspan: 4,
            },
          ],
          subscribeActions: [
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceShow',
              type: 'TOGGLE_OFF',
            },
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceHide',
              type: 'TOGGLE_ON',
            },
          ],
        },
        {
          elementType: 'InputSwitch',
          name: 'filter',
          label: 'Filter',
          required: false,
          default: false,
        },
        {
          elementType: 'InputText',
          name: 'filterBy',
          required: false,
          label: 'Filter By',
        },
        {
          elementType: 'InputText',
          name: 'placeholder',
          required: false,
          label: 'Placeholder',
        },
        {
          elementType: 'InputSwitch',
          name: 'showClear',
          label: 'Show Clear',
          required: false,
          default: true,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
