import { NgModule } from '@angular/core';
import { GenericDropdownComponent } from './generic-dropdown/generic-dropdown.component';
import { GenericButtonComponent } from './generic-button/generic-button.component';
import { GenericDatePickerComponent } from './generic-date-picker/generic-date-picker.component';
import { GenericTextInputComponent } from './generic-text-input/generic-text-input.component';
import { GenericCheckboxComponent } from './generic-checkbox/generic-checkbox.component';
import { GenericMultiselectComponent } from './generic-multiselect/generic-multiselect.component';
import { GenericPToastComponent } from './generic-p-toast/generic-p-toast.component';

import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { MultiSelectModule } from 'primeng/multiselect';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GenericAccordionComponent } from './generic-accordion/generic-accordion.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    GenericDropdownComponent,
    GenericButtonComponent,
    GenericDatePickerComponent,
    GenericTextInputComponent,
    GenericCheckboxComponent,
    GenericMultiselectComponent,
    GenericAccordionComponent,
    GenericPToastComponent,
  ],
  imports: [
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    InputTextModule,
    CheckboxModule,
    ToastModule,
    FormsModule,
    CommonModule,
    MatSelectModule,
  ],
  exports: [
    GenericDropdownComponent,
    GenericButtonComponent,
    GenericDatePickerComponent,
    GenericTextInputComponent,
    GenericCheckboxComponent,
    GenericMultiselectComponent,
    GenericAccordionComponent,
    GenericPToastComponent,
  ],
})
export class GenericModule {}
