// angular
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// rxjs
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class FormGroupService {
  private formSubject: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(null);

  constructor() {}

  getFormGroup(): Observable<UntypedFormGroup> {
    return this.formSubject.pipe(filter((form: UntypedFormGroup) => !!form));
  }

  updateFormGroup(formGroup: UntypedFormGroup): void {
    this.formSubject.next(formGroup);
  }
}
