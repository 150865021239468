import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShellLoader } from '../../../../shell/src/app/ShellLoader';
import { Observable } from 'rxjs';

@Injectable()
export class FormService {
  backendUrl = '';
  appKey = '';

  constructor(private httpClient: HttpClient, private shellLoader: ShellLoader) {
    this.shellLoader.configLoaded.subscribe(() => {
      this.backendUrl = this.shellLoader.backendUrl;
      this.appKey = this.shellLoader.config.key;
    });
  }

  submitFormWithUUID(formData: any, method: 'post' | 'patch' = 'post'): Observable<any> {
    const url = new URL(this.backendUrl);
    url.pathname += ['/api', 'questionnaires', 'v1', 'forms'].join('/');

    return this.httpClient[method](url.href, {
      key: this.appKey,
      patient_data: formData,
    });
  }

  getForm(formSlug: string): Observable<any> {
    const url = new URL(this.backendUrl);
    url.pathname += ['/api', 'questionnaires', 'v1', 'form_templates'].join('/');

    return this.httpClient.get(url.href, {
      params: {
        slug: formSlug,
        key: this.appKey,
      },
    });
  }
}
