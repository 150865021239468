import { Accordion } from '@shared/models/accordion';
import { CarechartAccordionOptions } from '@shared/options';

export class CarechartAccordion extends Accordion {
  elementTypeName: string = 'CarechartAccordion';

  isComplete: boolean;

  constructor(options: CarechartAccordionOptions) {
    super(options);
    this.isComplete = options.isComplete || false;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      isComplete: this.isComplete,
    });
  }
}
