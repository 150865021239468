// models
import { FormLayout } from '@shared/models/form-layout';
import { ButtonOptions } from '@shared/options';

export class Button extends FormLayout {
  elementTypeName: string = 'Button';

  type: string;
  label: string;

  constructor(options?: ButtonOptions) {
    super(options);
    this.type = options.type || 'button';
    this.label = options.label;
  }

  toJSON() {
    const result = super.toJSON();
    return Object.assign(result, {
      type: this.type,
      label: this.label,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'type',
          label: 'Type',
          required: true,
        },
        {
          elementType: 'InputText',
          name: 'label',
          label: 'Label',
          required: true,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
