// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';

// Configs
import { EngageUtilsConfig } from './engage-utils-config';

// Directives
import { EngageSrcDirective } from './engage-src.directive';
import { EngageHrefDirective } from './engage-href.directive';

// Services
import { EngageSerializableStoreService } from './engage-serializable-store.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EngageApiResponseInterceptor } from './engage-api-response.interceptor';

@NgModule({
  declarations: [EngageSrcDirective, EngageHrefDirective],
  exports: [EngageSrcDirective, EngageHrefDirective],
  providers: [
    EngageSerializableStoreService,
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: EngageApiResponseInterceptor },
  ],
})
export class EngageUtilsModule {
  static init(config?: EngageUtilsConfig): ModuleWithProviders<EngageUtilsModule> {
    return {
      ngModule: EngageUtilsModule,
      providers: [{ provide: EngageUtilsConfig, useValue: config ?? {} }],
    };
  }
}
