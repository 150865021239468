import { Component, Input } from '@angular/core';
import { valOrEval } from '../mood-utils/val-or-eval';

@Component({
  selector: 'mood-code-display',
  templateUrl: './mood-code-display.component.html',
  styleUrls: ['./mood-code-display.component.scss'],
})
export class MoodCodeDisplayComponent {
  @Input() value: string;
  @Input() label: string;

  get codeValue(): string {
    return valOrEval(this.value);
  }
}
