import { Component } from '@angular/core';
import { FormElementComponent } from '@shared/components/form-element/form-element.component';
import { StaticImage } from '@shared/models';

@Component({
  selector: 'app-static-image',
  templateUrl: './static-image.component.html',
  styleUrls: ['./static-image.component.scss'],
})
export class StaticImageComponent extends FormElementComponent {
  item: StaticImage;
}
