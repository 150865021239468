export enum FormActionType {
  // Set item.hidden = false
  TOGGLE_ON = 'TOGGLE_ON',
  // Set item.hidden = true
  TOGGLE_OFF = 'TOGGLE_OFF',
  // Prefill form control value with action.data
  PRE_FILL = 'PRE_FILL',
  // Prefill form control value with publisher's value.
  // If action.data is set, then it will prefill form control value with publishersValue[action.data]
  DYNAMIC_PREFILL = 'DYNAMIC_PREFILL',
  // Prefill form control value with publisher's value inside an object.
  // action.data specifies the key.
  OBJECT_DYNAMIC_PREFILL = 'OBJECT_DYNAMIC_PREFILL',
  // Set item.readonly = true
  READONLY_TRUE = 'READONLY_TRUE',
  // Set item.readonly = false
  READONLY_FALSE = 'READONLY_FALSE',
  // Set item[attribute] = true
  SET_ATTRIBUTE_TRUE = 'SET_ATTRIBUTE_TRUE',
  // Set item[attribute] = false
  SET_ATTRIBUTE_FALSE = 'SET_ATTRIBUTE_FALSE',
}
