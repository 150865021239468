export enum FormActionCondition {
  // If the field does not have a value
  EMPTY = 'EMPTY',
  // If the field has a value
  NON_EMPTY = 'NON_EMPTY',
  // If field === field2
  EQUAL = 'EQUAL',
  // if field !== field2
  NOT_EQUAL = 'NOT_EQUAL',
  // if field.includes(field2)
  // Used for ListFormComponents only.
  INCLUDE = 'INCLUDE',
  // if !field.includes(field2)
  // Used for ListFormComponents only.
  NOT_INCLUDE = 'NOT_INCLUDE',
  // Triggered when form is first initialized.
  ON_FORM_INIT = 'ON_FORM_INIT',
  // value is x days greater than today
  TIME_GREATER_THAN_TODAY = 'TIME_GREATER_THAN_TODAY',
  // value is x days less than today
  TIME_LESS_THAN_TODAY = 'TIME_LESS_THAN_TODAY',
  // day is today
  TIME_EQUALS_TODAY = 'TIME_EQUALS_TODAY',
}
