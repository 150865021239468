// Angular
import {
  AbstractControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { SafeStyle } from '@angular/platform-browser';

// Models
import { FormAction, FormActionJSON } from '@root/models/form-action';
import { FormActionCondition } from '@root/models/form-action-condition.enum';
import { FormActionType } from '@root/models/form-action-type.enum';
import { ValidatorFactory } from '@root/models/validator-factory';
import { FormValidator } from '@shared/models/validators/form-validator';
import { FormValidatorType } from '@shared/models/validators/form-validator-type.enum';
import { FormElementOptions, FormValidatorOptions } from '@shared/options';
import { FormElement } from './form-element';

// Utils
import { cloneDeep } from 'lodash';
import * as uuid from 'uuid';
import { FormContainer } from './form-container';

export abstract class FormElementImpl implements FormElement {
  elementTypeName: string = 'FormElementImpl';
  parent?: FormContainer;

  id: string;
  model: any;
  name: string;
  colspan: number;

  onChangeEventName: string;
  hidden: boolean;
  cssClass: string;
  validators: FormValidator[] = [];
  required: boolean;

  default: any;

  publishActions: FormAction[] = [];
  subscribeActions: FormAction[] = [];

  formControl: AbstractControl = new UntypedFormControl('');

  styles: string;

  abstract getContainerIds(): string[];

  constructor(options?: FormElementOptions) {
    this.id = uuid.v4();
    if (!!options) {
      this.name = options.name;
      this.colspan = options.colspan || 12;
      this.onChangeEventName = options.onChangeEventName;
      this.hidden = options.hidden;
      this.cssClass = options.cssClass;
      this.required = options.required || false;
      this.default = options.default;
      this.validators = (options.validators || []).map(
        (validator: FormValidatorOptions) => new FormValidator(validator)
      );
      this.publishActions = (options.publishActions || []).map(
        (action: FormActionJSON) => new FormAction(action)
      );
      this.subscribeActions = (options.subscribeActions || []).map(
        (action: FormActionJSON) => new FormAction(action)
      );
    }
  }

  clone(): FormElement {
    const clonedElement = cloneDeep(this);
    // replace id
    clonedElement.id = uuid.v4();
    return clonedElement;
  }

  toJSON(): object {
    return {
      elementType: this.elementTypeName,
      name: this.name,
      colspan: this.colspan,
      onChangeEventName: this.onChangeEventName,
      hidden: this.hidden,
      cssClass: this.cssClass,
      required: this.required,
      default: this.default,
      validators: this.validators.map((validator: FormValidator) =>
        validator.toJSON()
      ),
      publishActions: this.publishActions.map((action: FormAction) =>
        action.toJSON()
      ),
      subscribeActions: this.subscribeActions.map((action: FormAction) =>
        action.toJSON()
      ),
    };
  }

  updateFromJSON(json: object): void {
    // basic update
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        this[key] = json[key];
      }
    }

    // form actions update
    this.publishActions =
      !!json['publishActions'] && Array.isArray(json['publishActions'])
        ? this.formActionsParser(json['publishActions'])
        : [];
    this.subscribeActions =
      !!json['subscribeActions'] && Array.isArray(json['subscribeActions'])
        ? this.formActionsParser(json['subscribeActions'])
        : [];
    // validators update
    this.validators = (
      !!json['validators'] && Array.isArray(json['validators'])
        ? json['validators']
        : []
    ).map((validator: FormValidatorOptions) => new FormValidator(validator));
  }

  toFormControl(value?: any): AbstractControl {
    this.formControl = new UntypedFormControl(
      { value: value || this.default },
      {
        validators: ValidatorFactory.generateValidators(this),
        updateOn: 'change',
      }
    );
    return this.formControl;
  }

  formName(): string {
    if (this.name === undefined) {
      return '';
    }

    return this.name.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) {
        return '';
      }

      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return {
      elementType: 'RootContainer',
      label: '',
      themeClass: 'drop-shadow-theme',
      children: [
        {
          elementType: 'Button',
          label: 'Save Changes',
          required: false,
          type: 'submit',
        },
        {
          elementType: 'Spacing',
          name: 'spacing',
          colspan: 4,
          required: false,
          height: '20px',
        },
        {
          elementType: 'Accordion',
          name: 'basicProperties',
          colspan: 12,
          header: 'Basic Properties',
          lazyLoad: false,
          defaultOpen: false,
          children: [
            {
              elementType: 'InputText',
              name: 'name',
              label: 'Name',
              pKeyFilter: 'alphanum',
              required: true,
            },
            {
              elementType: 'InputText',
              name: 'colspan',
              label: 'Column Span',
              pKeyFilter: 'pint',
              required: false,
            },
            {
              elementType: 'InputText',
              name: 'onChangeEventName',
              label: 'On Change Event Name',
              required: false,
            },
            {
              elementType: 'InputSwitch',
              name: 'hidden',
              label: 'Hidden',
              required: false,
              default: false,
            },
            {
              elementType: 'InputSwitch',
              name: 'required',
              label: 'Required',
              required: false,
              default: false,
            },
            {
              elementType: 'InputSwitch',
              name: 'disabled',
              label: 'Disabled',
              required: false,
              default: false,
            },
            // @ts-ignore
          ].concat(extraChildren),
        },
        {
          elementType: 'Accordion',
          name: 'formActions',
          colspan: 12,
          header: 'Form Actions',
          lazyLoad: false,
          defaultOpen: false,
          children: [
            {
              elementType: 'StaticText',
              name: 'heading',
              colspan: 12,
              content: 'Publish Actions',
              type: 'h4',
            },
            {
              elementType: 'RepeatableContainer',
              name: 'publishActions',
              colspan: 12,
              addButtonLabel: 'Add new publish action',
              minItems: 0,
              children: [
                {
                  elementType: 'Dropdown',
                  name: 'condition',
                  colspan: 6,
                  label: 'Action Condition',
                  options: [
                    {
                      label: 'On Empty',
                      value: FormActionCondition.EMPTY.toString(),
                    },
                    {
                      label: 'On Non-Empty',
                      value: FormActionCondition.NON_EMPTY.toString(),
                    },
                    {
                      label: 'On Equal',
                      value: FormActionCondition.EQUAL.toString(),
                    },
                    {
                      label: 'On Not Equal',
                      value: FormActionCondition.NOT_EQUAL.toString(),
                    },
                    {
                      label: 'On Include',
                      value: FormActionCondition.INCLUDE.toString(),
                    },
                    {
                      label: 'On Not Include',
                      value: FormActionCondition.NOT_INCLUDE.toString(),
                    },
                    {
                      label: 'On Form Init',
                      value: FormActionCondition.ON_FORM_INIT.toString(),
                    },
                    {
                      label: 'On Time Greater Than Today',
                      value:
                        FormActionCondition.TIME_GREATER_THAN_TODAY.toString(),
                    },
                    {
                      label: 'On Time Less Than Today',
                      value:
                        FormActionCondition.TIME_LESS_THAN_TODAY.toString(),
                    },
                    {
                      label: 'On Time Is Equal To Today',
                      value: FormActionCondition.TIME_EQUALS_TODAY.toString(),
                    },
                  ],
                  filterBy: 'label',
                  placeholder: 'Select a Condition',
                },
                {
                  elementType: 'InputText',
                  name: 'customCondition',
                  required: false,
                  label: 'Custom Condition Value',
                  colspan: 6,
                },
                {
                  elementType: 'InputText',
                  name: 'linkName',
                  pKeyFilter: 'alphanum',
                  required: false,
                  label: 'Link Name',
                  colspan: 4,
                },
                {
                  elementType: 'InputText',
                  name: 'data',
                  required: false,
                  label: 'Extraneous Data',
                  colspan: 6,
                },
              ],
            },
            {
              elementType: 'StaticText',
              name: 'heading',
              colspan: 12,
              content: 'Subscribe Actions',
              type: 'h4',
            },
            {
              elementType: 'RepeatableContainer',
              name: 'subscribeActions',
              colspan: 12,
              addButtonLabel: 'Add new subscribe action',
              minItems: 0,
              children: [
                {
                  elementType: 'Dropdown',
                  name: 'type',
                  colspan: 6,
                  label: 'Action Type',
                  options: [
                    {
                      label: 'Toggle On',
                      value: FormActionType.TOGGLE_ON.toString(),
                    },
                    {
                      label: 'Toggle Off',
                      value: FormActionType.TOGGLE_OFF.toString(),
                    },
                    {
                      label: 'Pre-fill',
                      value: FormActionType.PRE_FILL.toString(),
                    },
                    {
                      label: 'Dynamic Pre-fill',
                      value: FormActionType.DYNAMIC_PREFILL.toString(),
                    },
                    {
                      label: 'Dynamic Pre-fill Object',
                      value: FormActionType.OBJECT_DYNAMIC_PREFILL.toString(),
                    },
                    {
                      label: 'Read Only On',
                      value: FormActionType.READONLY_TRUE.toString(),
                    },
                    {
                      label: 'Read Only Off',
                      value: FormActionType.READONLY_FALSE.toString(),
                    },
                    {
                      label: 'Set Attribute True',
                      value: FormActionType.SET_ATTRIBUTE_TRUE.toString(),
                    },
                    {
                      label: 'Set Attribute False',
                      value: FormActionType.SET_ATTRIBUTE_FALSE.toString(),
                    },
                  ],
                  filterBy: 'label',
                  placeholder: 'Select an Action',
                },
                {
                  elementType: 'InputText',
                  name: 'linkName',
                  pKeyFilter: 'alphanum',
                  required: false,
                  label: 'Link Name',
                  colspan: 6,
                },
                {
                  elementType: 'InputText',
                  name: 'data',
                  required: false,
                  label: 'Extraneous Data',
                  colspan: 10,
                },
              ],
            },
          ],
        },
        {
          elementType: 'Accordion',
          name: 'validatorsSection',
          colspan: 12,
          header: 'Validators',
          lazyLoad: false,
          defaultOpen: false,
          children: [
            {
              elementType: 'RepeatableContainer',
              name: 'validators',
              colspan: 12,
              addButtonLabel: 'Add new validator',
              minItems: 0,
              // TODO: fix form actions in repeatable containers
              children: [
                {
                  elementType: 'InputText',
                  name: 'name',
                  pKeyFilter: 'alpha',
                  label: 'Name',
                  colspan: 4,
                },
                {
                  elementType: 'InputText',
                  name: 'message',
                  pKeyFilter: 'alphanum',
                  label: 'Message',
                  colspan: 8,
                },
                {
                  elementType: 'Dropdown',
                  name: 'type',
                  colspan: 5,
                  label: 'Validator Type',
                  options: [
                    {
                      label: 'Email',
                      value: FormValidatorType.EMAIL.toString(),
                    },
                    {
                      label: 'Phone',
                      value: FormValidatorType.PHONE.toString(),
                    },
                    {
                      label: 'Regex',
                      value: FormValidatorType.REGEX.toString(),
                    },
                    {
                      label: 'Time greater than today',
                      value:
                        FormValidatorType.TIME_GREATER_THAN_TODAY.toString(),
                    },
                    {
                      label: 'Time less than today',
                      value: FormValidatorType.TIME_LESS_THAN_TODAY.toString(),
                    },
                    {
                      label: 'Time equal to today',
                      value: FormValidatorType.TIME_EQUAL_TO_TODAY.toString(),
                    },
                  ],
                  filterBy: 'label',
                  placeholder: 'Select a type',
                  publishActions: [
                    {
                      linkName: 'regexValidator',
                      condition: 'CUSTOM',
                      customCondition: 'REGEX',
                    },
                  ],
                },
                {
                  elementType: 'InputText',
                  name: 'customValue',
                  pKeyFilter: 'alphanum',
                  required: false,
                  label: 'Custom Value',
                  colspan: 5,
                  subscribeActions: [
                    { type: 'TOGGLE_ON', linkName: 'regexValidator' },
                  ],
                },
              ],
            },
          ],
        },
        {
          elementType: 'Accordion',
          name: 'accordion',
          colspan: '12',
          required: false,
          validators: [],
          publishActions: [],
          subscribeActions: [],
          children: [
            {
              elementType: 'InputText',
              name: 'source',
              colspan: '12',
              hidden: false,
              required: false,
              validators: [],
              publishActions: [],
              subscribeActions: [],
              label: 'Source',
              pKeyFilter: '/[\\s\\S]*/',
              inputType: 'text',
            },
            {
              elementType: 'InputText',
              name: 'destination',
              colspan: '12',
              hidden: false,
              required: false,
              validators: [],
              publishActions: [],
              subscribeActions: [],
              label: 'Destination',
              pKeyFilter: '/[\\s\\S]*/',
              inputType: 'text',
            },
            {
              elementType: 'InputText',
              name: 'resource',
              colspan: '12',
              hidden: false,
              required: false,
              validators: [],
              publishActions: [],
              subscribeActions: [],
              label: 'Resource',
              pKeyFilter: '/[\\s\\S]*/',
              inputType: 'text',
            },
            {
              elementType: 'InputText',
              name: 'template',
              colspan: '12',
              hidden: false,
              required: false,
              validators: [],
              publishActions: [],
              subscribeActions: [],
              label: 'Template',
              pKeyFilter: '/[\\s\\S]*/',
              inputType: 'text',
            },
            {
              elementType: 'Dropdown',
              name: 'behavior',
              colspan: 12,
              required: false,
              validators: [],
              publishActions: [],
              subscribeActions: [],
              label: 'Behavior',
              options: [
                { label: 'DEFAULT', value: '1' },
                { label: 'ENUM_MAP', value: '2' },
                { label: 'DICT_LIST', value: '3' },
                { label: 'REFERENCE', value: '4' },
                { label: 'COMPOSITE_KEY', value: '5' },
              ],
              horizontal: null,
              filter: true,
              filterBy: 'label',
              placeholder: 'Select an option',
            },
            {
              elementType: 'Dropdown',
              name: 'cast_type',
              colspan: 12,
              required: false,
              validators: [],
              publishActions: [],
              subscribeActions: [],
              label: 'Cast Type',
              options: [
                { label: 'INT_TO_STRING', value: '1' },
                { label: 'STRING_TO_INT', value: '2' },
              ],
              horizontal: null,
              filter: true,
              filterBy: 'label',
              placeholder: 'Select an option',
            },
            {
              elementType: 'InputText',
              name: 'group_number',
              colspan: '12',
              hidden: false,
              required: false,
              validators: [],
              publishActions: [],
              subscribeActions: [],
              label: 'Group Number',
              pKeyFilter: '/[\\s\\S]*/',
              inputType: 'text',
            },
          ],
          header: 'FHIR Mappings',
          lazyLoad: false,
          defaultOpen: false,
        },
      ],
    };
  }

  getColSpan(): number {
    return +this.colspan;
  }

  hasMetActionCondition(action: FormAction) {
    let conditionMet = false;
    switch (action.condition) {
      case FormActionCondition.EMPTY:
        conditionMet = !this.formControl.value;
        break;
      case FormActionCondition.NON_EMPTY:
        conditionMet = !!this.formControl.value;
        break;
      case FormActionCondition.EQUAL:
        conditionMet = this.formControl.value === action.customCondition;
        break;
      case FormActionCondition.NOT_EQUAL:
        conditionMet = this.formControl.value !== action.customCondition;
        break;
      case FormActionCondition.INCLUDE:
        conditionMet = (this.formControl.value || []).includes(
          action.customCondition
        );
        break;
      case FormActionCondition.NOT_INCLUDE:
        conditionMet = !(this.formControl.value || []).includes(
          action.customCondition
        );
        break;
      case FormActionCondition.ON_FORM_INIT:
        // conditionMet is handled in dynamic-component as it is only true when publishActions is called
        // onInit.
        conditionMet = false;
        break;
    }
    return conditionMet;
  }

  /**
   * Handles actions related to FormElement.
   * @param action: the form action to run
   * @param globalDisabled: true if the form is disabled globally.
   */
  handleAction(action: FormAction, globalDisabled: boolean): void {
    switch (action.type) {
      case FormActionType.TOGGLE_ON:
        this.hidden = false;
        break;
      case FormActionType.TOGGLE_OFF:
        this.hidden = true;
        break;
      case FormActionType.PRE_FILL:
        this.hidden = false;
        this.formControl.patchValue(action.data);
        break;
      case FormActionType.DYNAMIC_PREFILL:
        const valueToPatch = action.data
          ? action.formValue[action.data]
          : action.formValue;
        this.formControl.patchValue(valueToPatch);
        break;
      case FormActionType.OBJECT_DYNAMIC_PREFILL:
        const controlValueObject = this.formControl.value || {};
        controlValueObject[action.data] = action.formValue;
        this.formControl.patchValue(controlValueObject);
        break;
      case FormActionType.SET_ATTRIBUTE_TRUE:
        this[action.data] = true;
        break;
      case FormActionType.SET_ATTRIBUTE_FALSE:
        this[action.data] = false;
        break;
    }
  }

  private formActionsParser(actions: FormActionJSON[]) {
    return actions
      .filter((action: FormActionJSON) => !!action.linkName)
      .map((action: FormActionJSON) => new FormAction(action));
  }
}
