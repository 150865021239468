// Angular
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Markdown
import { Marked } from '@ts-stack/markdown';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { MarkdownText } from '@shared/models';

// Services
import { FormEventService } from '@root/services/form-event.service';
import { FormGroupService } from '@root/services/form-group.service';
import { FormUpdateService } from '@root/services/form-update.service';

@Component({
  selector: 'app-markdown-text',
  templateUrl: './markdown-text.component.html',
  styleUrls: ['./markdown-text.component.scss'],
})
export class MarkdownTextComponent extends FormElementComponent {
  item: MarkdownText;

  constructor(
    fgs: FormGroupService,
    fus: FormUpdateService,
    public fes: FormEventService,
    public sanitizer: DomSanitizer
  ) {
    super(fgs, fus, fes);
  }

  parseMarkdown(text: string) {
    return Marked.parse(text);
  }
}
