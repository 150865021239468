// Models
import { FormLayout } from '@shared/models/form-layout';
import { StaticTextOptions } from '@shared/options';

export class StaticText extends FormLayout {
  elementTypeName: string = 'StaticText';

  content: string;
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'hr';

  constructor(options: StaticTextOptions) {
    super(options);
    this.content = options.content;
    this.type = options.type;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      content: this.content,
      type: this.type,
    });
  }

  getTypeAsOptions() {
    return [
      { label: 'Heading 1', value: 'h1' },
      { label: 'Heading 2', value: 'h2' },
      { label: 'Heading 3', value: 'h3' },
      { label: 'Heading 4', value: 'h4' },
      { label: 'Heading 5', value: 'h5' },
      { label: 'Paragraph', value: 'p' },
      { label: 'Thematic break', value: 'hr' },
    ];
  }

  getEditFormJSON(extraChildren: object[] = []) {
    return super.getEditFormJSON([
      {
        elementType: 'InputTextarea',
        label: 'Content',
        colspan: 12,
        name: 'content',
        rows: 2,
        cols: 20,
        autoResize: true,
        resizable: false,
      },
      {
        elementType: 'Dropdown',
        name: 'type',
        label: 'type',
        colspan: 12,
        required: false,
        options: this.getTypeAsOptions(),
        filterBy: 'label',
        placeholder: 'Select a type',
      },
    ]);
  }
}
