<div class="p-fluid" [attr.data-name]="item.name">
  <span class="p-label">
    <label [htmlFor]="item.id">{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label>
    <app-patch-input-mask
      [item]="item"
      [formControl]="item.formControl"
      (onChange)="emitEvent()"
    ></app-patch-input-mask>
  </span>
  <app-form-validators [item]="item"></app-form-validators>
</div>
