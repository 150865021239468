import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_KEY, SHELL_DATA } from 'engage-common';
import { EngageSerializableStoreService } from 'engage-utils';
import { Observable } from 'rxjs';

const VERIFY_RESOURCE_PATH = 'api/oh/resources';
const REGISTER_RESOURCE_PATH = 'api/oh/patients';
const HEALTHCARD_VALIDATOR_PATH = 'api/oh/healthcard';
const CAPTCHA_ENDPOINT = 'api/tokens/captcha_key';
const GET_DROPOFF_SITES = 'api/oh/units';
const GET_PATIENT_BY_ENGAGE_TOKEN = 'api/oh/token/resources';
const CREATE_OLIS_ORDER_PATH = 'api/oh/create_olis_order';
const VALIDATE_TOKEN_PATH = 'api/oh/validate_token';
const LOGIN_PATH = 'api/oh/tokens';
const GET_PATIENT_BY_RESOURCE_TOKEN = 'api/oh/patients';

@Injectable()
export class KitClient {
  backendUrl = '';

  constructor(
    private _httpClient: HttpClient,
    @Inject(APP_KEY) private _appKey: string,
    @Inject(SHELL_DATA) private _shellData: any,
    private _engageStore: EngageSerializableStoreService
  ) {
    this.backendUrl = this._shellData.backendUrl;
  }

  private _urlFor(path: string): string {
    if (this.backendUrl === '') {
      throw new Error('[KitApiService]: backendUrl not loaded properly.');
    }

    if (!this._appKey) {
      throw new Error('[KitApiService]: appKey not loaded properly.');
    }

    return `${this.backendUrl}/${path}`;
  }

  private _constructBody(data: object): any {
    if (data.hasOwnProperty('key')) {
      throw new Error(
        '[KitApiService]: API call was attempted that overwrote the key body parameter'
      );
    }

    return {
      key: this._appKey,
      ...data,
    };
  }

  validateToken(token: string): Observable<any> {
    const url = this._urlFor(VALIDATE_TOKEN_PATH);
    const body = { token, key: this._appKey };
    return this._httpClient.post(url, body);
  }

  login(username: string, password: string): Observable<any> {
    const url = this._urlFor(LOGIN_PATH);
    const body = { username, password, key: this._appKey };
    return this._httpClient.post(url, body);
  }

  verifyKit(kitId: string): Observable<any> {
    const url = this._urlFor(VERIFY_RESOURCE_PATH);
    const accessToken = this._engageStore.retrieve('accessToken');

    const params = {
      key: this._appKey,
      resource_id: kitId,
    };

    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    return this._httpClient.get(url, { params, headers });
  }

  register(formData: any, resourceId: string): Observable<any> {
    const url = this._urlFor(REGISTER_RESOURCE_PATH);
    const accessToken = this._engageStore.retrieve('accessToken');

    const body = this._constructBody({
      form: formData,
      resource_id: resourceId,
    });
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);

    return this._httpClient.post(url, body, { headers });
  }

  tradeCaptchaForToken() {
    const url = this._urlFor(CAPTCHA_ENDPOINT);
    const captchakey = this._engageStore.retrieve('captchaResult');

    const body = this._constructBody({
      recaptcha_key: captchakey,
    });

    return this._httpClient.post(url, body);
  }

  validateHealthCard(
    healthcard: string,
    code: string,
    postalCode?: string,
    dob?: string
  ): Observable<any> {
    const url = this._urlFor(HEALTHCARD_VALIDATOR_PATH);
    const accessToken = this._engageStore.retrieve('accessToken');

    const body = this._constructBody({
      healthcard,
      code,
    });

    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);

    return this._httpClient.post(url, body, { headers });
  }

  submitOrderToOlis(
    resourceId: string,
    unitExternalIdentifier: string,
    engageToken: string
  ): Observable<any> {
    const url = this._urlFor(CREATE_OLIS_ORDER_PATH);

    const body = this._constructBody({
      resource_id: resourceId,
      unit_external_identifier: unitExternalIdentifier,
    });
    if (engageToken) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${engageToken}`);
      return this._httpClient.post(url, body, { headers });
    } else {
      return this._httpClient.post(url, body);
    }
  }

  getDropoffSites(): Observable<any> {
    const url = this._urlFor(GET_DROPOFF_SITES);
    const params = {
      key: this._appKey,
    };
    return this._httpClient.get(url, { params });
  }

  getPatientInfoByEngageToken(engageToken: string): Observable<any> {
    const url = this._urlFor(GET_PATIENT_BY_ENGAGE_TOKEN);
    const params = {
      key: this._appKey,
    };
    const headers = new HttpHeaders().set('Authorization', `Bearer ${engageToken}`);
    return this._httpClient.get(url, { params, headers });
  }

  getPatientInfoByResourceToken(resource_id: string, permanentToken: string): Observable<any> {
    const url = this._urlFor(GET_PATIENT_BY_RESOURCE_TOKEN);
    const key = this._appKey;
    const params = {
      key,
      resource_id,
    };
    const headers = new HttpHeaders().set('Authorization', `Bearer ${permanentToken}`);
    return this._httpClient.get(url, { params, headers });
  }
}
