// angular
import { Component } from '@angular/core';

// components
import { FormContainerComponent } from '@shared/components/form-container/form-container.component';

// models
import { RepeatableContainer } from '@shared/models';

@Component({
  selector: 'app-repeatable-container',
  templateUrl: './repeatable-container.component.html',
  styleUrls: ['./repeatable-container.component.scss'],
})
export class RepeatableContainerComponent extends FormContainerComponent {
  item: RepeatableContainer;
}
