<label *ngIf="!form; else withForm" class="container-input">
  <textarea
    (input)="onInput.emit(value)"
    [placeholder]="inputPlaceHolder"
    [(ngModel)]="value"
    [maxlength]="maxLength"
    class="input-field"
    (input)="valueChange($event)"
  ></textarea>
  <div *ngIf="error" class="alert alert-danger error-alert" role="alert">
    {{ error }}
  </div>
</label>

<ng-template #withForm>
  <label class="container-input" [formGroup]="form">
    {{ inputLabel }}
    <textarea
      [formControlName]="formData.controlName"
      [name]="formData.controlName"
      [placeholder]="inputPlaceHolder"
      [maxlength]="maxLength"
      (input)="valueChange($event)"
    ></textarea>
    <small *ngIf="helpText" [innerHTML]="inputHelpText"></small>
  </label>
</ng-template>
