import { NgModule } from '@angular/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { CameraAnimationComponent } from './camera-animation/camera-animation.component';
import { ScannerComponent } from './scanner/scanner.component';
import { ScreenComponent } from './screen/screen.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { ProgressBarModule } from '../progress/progress.component';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FooterButtonComponent } from './footer-button/footer-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    ScannerComponent,
    CameraAnimationComponent,
    ScreenComponent,
    HeaderComponent,
    FooterComponent,
    FooterButtonComponent,
  ],
  imports: [
    CommonModule,
    ZXingScannerModule,
    MatSidenavModule,
    MatButtonModule,
    ProgressBarModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    ScannerComponent,
    CameraAnimationComponent,
    ScreenComponent,
    HeaderComponent,
    FooterComponent,
    FooterButtonComponent,
  ],
})
export class KitSubmitModule {}
