// Angular
import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

// Interfaces
import { ModuleConfig, ShellConfig } from './interfaces/shell-config';

const moduleConfigToRoute = (moduleConfig) => {
  const route: Route = {
    path: moduleConfig.routerPath,
    loadChildren: () =>
      import(`./modules/${moduleConfig.module}`).then((m) => {
        return m[moduleConfig.moduleName];
      }),
  };

  // configure auxilary outlets if the config provides it
  // in most cases this if clause does not execute
  if (moduleConfig.outlet) {
    route.outlet = moduleConfig.outlet;
  }

  return route;
};

@Injectable({
  providedIn: 'root',
})
export class ShellLoader {
  config: ShellConfig;

  configLoaded = new ReplaySubject<void>(1);
  showDebugOverlay = false;

  constructor(private _router: Router) {}

  init(): Promise<any> {
    const routes = this.config.application.moduleConfigurations.map((config) =>
      moduleConfigToRoute(config)
    );
    this._router.resetConfig(routes);

    const navigateToInitialRoutePromise = this._navigateToInitialRoute();
    this.configLoaded.next();

    return navigateToInitialRoutePromise;
  }

  private _navigateToInitialRoute(): Promise<boolean> {
    const initialRouteConfig = this.initialRoute;

    if (!initialRouteConfig) {
      return;
    }

    const navigatePath = [
      { outlets: { primary: initialRouteConfig.primary, ...initialRouteConfig.auxiliaryOutlets } },
    ];

    return this._router.navigate(navigatePath, { queryParamsHandling: 'preserve' });
  }

  get moduleConfigurations(): ModuleConfig[] {
    if (!this.config) {
      return [];
    }

    return this.config.application.moduleConfigurations;
  }

  get initialRoute() {
    if (!this.config) {
      return;
    }

    return this.config.application.initialRoute;
  }

  get outlets(): string[] {
    return this.moduleConfigurations.map((moduleConfig) => moduleConfig.outlet);
  }

  get debug(): boolean {
    const v1Location = this.config?.implementation?.extraInfo?.content?.debug;
    const v2Location = this.config?.content?.debug;

    return v2Location ?? v1Location ?? false;
  }

  set debug(val: boolean) {
    const v1Location = this.config?.implementation?.extraInfo?.content;
    const v2Location = this.config?.content;

    (v2Location ?? v1Location).debug = val;
  }

  get debugOverlayEnabled(): boolean {
    return this.debug && this.showDebugOverlay;
  }

  get progressBarEnabled(): boolean {
    const v1Location = this.config?.implementation?.extraInfo?.content?.progressBarEnabled;
    const v2Location = this.config?.content?.progressBarEnabled;

    return v2Location ?? v1Location ?? false;
  }

  get initialState() {
    const v1Location = this.config?.implementation?.extraInfo?.content?.machine?.initial;
    const v2Location = this.config?.content?.machine?.initial;

    return v2Location ?? v1Location ?? '';
  }

  get enableAvailability() {
    const v1Location = this.config?.implementation?.extraInfo?.enableAvailability;

    return v1Location ?? false;
  }

  get content() {
    const v1Location = this.config?.implementation?.extraInfo?.content;
    const v2Location = this.config?.content;

    // use v1 over v2 to account for edge case where we use both (ie. embedding openclinic widget within chl-patient-portal)
    return v1Location ?? v2Location ?? {};
  }

  get dropdownItems() {
    const v1Location = this.config?.implementation?.extraInfo?.dropdownItems;
    const v2Location = this.config?.dropdownItems;

    return v2Location ?? v1Location ?? {};
  }

  get locales() {
    const v1Location = this.config?.implementation?.extraInfo?.locales;
    const v2Location = this.config?.locales;

    return v2Location ?? v1Location ?? {};
  }

  get contextMappings() {
    const v1Location = this.config?.implementation?.extraInfo?.contextMappings;
    const v2Location = this.config?.contextMappings;

    return v2Location ?? v1Location ?? {};
  }

  get services() {
    const v1Location = this.config?.implementation?.extraInfo?.services;
    const v2Location = this.config?.services;

    return v2Location ?? v1Location ?? {};
  }

  get mode() {
    const v1Location = this.config?.implementation?.extraInfo?.mode;
    const v2Location = this.config?.mode;

    return v2Location ?? v1Location ?? '';
  }

  get logo() {
    const v1Location = this.config?.implementation?.extraInfo?.logo;
    const v2Location = this.config?.logo;

    return v2Location ?? v1Location ?? '';
  }

  get googleMapConfigs() {
    const v1Location = this.config?.implementation?.extraInfo?.googleMapConfigs;
    const v2Location = this.config?.googleMapConfigs;

    return v2Location ?? v1Location ?? {};
  }

  get daysInAdvance() {
    const v1Location = this.config?.implementation?.extraInfo?.daysInAdvance;
    const v2Location = this.config?.daysInAdvance;

    return v2Location ?? v1Location ?? 7;
  }

  get engageUrl() {
    const v1Location = this.config?.implementation?.extraInfo?.engageUrl;
    const v2Location = this.config?.engageUrl;

    return v2Location ?? v1Location ?? {};
  }

  get googleApiKey() {
    const v1Location = this.config?.implementation?.extraInfo?.googleApiKey;
    const v2Location = this.config?.googleApiKey;

    return v2Location ?? v1Location ?? '';
  }

  get unitsUrl() {
    const v1Location = this.config?.implementation?.extraInfo?.unitsUrl;
    const v2Location = this.config?.unitsUrl;

    return v2Location ?? v1Location ?? '';
  }

  get serviceTypeUrl() {
    const v1Location = this.config?.implementation?.extraInfo?.serviceTypeUrl;
    const v2Location = this.config?.serviceTypeUrl;

    return v2Location ?? v1Location ?? '';
  }

  get backendUrl() {
    const v1Location = this.config?.implementation?.extraInfo?.backendUrl;
    const v2Location = this.config?.content.backendUrl;

    return v1Location ?? v2Location ?? '';
  }

  get contentMode() {
    return this.config?.content?.mode ?? '';
  }
}
