// Models
import { FormAction } from '@root/models/form-action';
import { FormActionCondition } from '@root/models/form-action-condition.enum';
import { FormComponent } from '@shared/models/form-component';
import { CalendarOptions } from '@shared/options';

const moment = (window as any).moment;

export class Calendar extends FormComponent {
  elementTypeName: string = 'Calendar';

  dateFormat: string;
  minDate: Date;
  maxDate: Date;
  selectionMode: 'single' | 'multiple' | 'range';
  showButtonBar: boolean;

  showTime: boolean;
  timeOnly: boolean;

  yearRange: string;

  locale: string;

  constructor(options: CalendarOptions) {
    super(options);
    this.dateFormat = options.dateFormat;
    this.minDate = options.minDate ? new Date(options.minDate) : null;
    this.maxDate = options.maxDate ? new Date(options.maxDate) : null;
    this.selectionMode = options.selectionMode;
    this.showButtonBar = options.showButtonBar;

    this.showTime = options.showTime;
    this.timeOnly = options.timeOnly;
    this.yearRange = options.yearRange || '1997:2047';

    this.locale = options.locale || 'en';
  }

  toJSON(): object {
    return Object.assign(super.toJSON(), {
      dateFormat: this.dateFormat,
      minDate: this.minDate,
      maxDate: this.maxDate,
      selectionMode: this.selectionMode,
      showButtonBar: this.showButtonBar,
      showTime: this.showTime,
      timeOnly: this.timeOnly,
      yearRange: this.yearRange,
      locale: this.locale,
    });
  }

  hasMetActionCondition(action: FormAction) {
    let conditionMet = super.hasMetActionCondition(action);
    if (conditionMet) {
      return true;
    }

    // there must be a custom condition attached.
    if (!action.customCondition) {
      return false;
    }

    const controlVal = this.formControl.value;

    // custom condition is formatted as <number> <unit>
    const [number, unit] = action.customCondition.split(' ');

    if (isNaN(+number) || !controlVal) {
      return false;
    }

    const today = moment.utc().startOf(unit);
    const momentControlVal = moment.utc(controlVal).startOf(unit);

    switch (action.condition) {
      case FormActionCondition.TIME_GREATER_THAN_TODAY:
        conditionMet = momentControlVal.diff(today, unit) < +number;
        break;
      case FormActionCondition.TIME_LESS_THAN_TODAY:
        conditionMet = momentControlVal.diff(today, unit) > +number;
        break;
      case FormActionCondition.TIME_EQUALS_TODAY:
        conditionMet = momentControlVal.diff(today, unit) === +number;
        break;
    }
    return conditionMet;
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'dateFormat',
          label: 'Date Format',
          required: true,
        },
        {
          elementType: 'Calendar',
          name: 'minDate',
          colspan: 12,
          label: 'Min Date',
          dateFormat: 'yy-mm-dd',
          selectionMode: 'single',
          showButtonBar: false,
        },
        {
          elementType: 'Calendar',
          name: 'maxDate',
          colspan: 12,
          label: 'Max Date',
          dateFormat: 'yy-mm-dd',
          selectionMode: 'single',
          showButtonBar: false,
        },
        {
          elementType: 'InputSwitch',
          name: 'showTime',
          label: 'Show Time',
          required: false,
          default: false,
        },
        {
          elementType: 'InputSwitch',
          name: 'timeOnly',
          label: 'Time Only',
          required: false,
          default: false,
        },
        {
          elementType: 'InputText',
          name: 'yearRange',
          label: 'Year Range',
          placeholder: '1997:2047',
          default: '1989:2020',
          required: true,
        },
        {
          elementType: 'InputText',
          name: 'locale',
          label: 'Locale',
          required: false,
          default: 'en',
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
