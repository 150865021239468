<div class="row no-gutters">
  <div
    *ngFor="let moduleConfig of shellLoader.moduleConfigurations"
    [class]="sizeToBootStrapGridClass(moduleConfig.styles.size)"
  >
    <app-module-outlet [moduleConfig]="moduleConfig"></app-module-outlet>
  </div>
</div>

<div class="html-inject"></div>
