import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mood-captcha',
  templateUrl: './mood-captcha.component.html',
  styleUrls: ['./mood-captcha.component.scss'],
})
export class MoodCaptchaComponent {
  @Input() siteKey: string;
  @Output() resolved = new EventEmitter<string>();
}
