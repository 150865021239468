import { MoodRadioButton } from '../mood-radio-group/mood-radio-group.component';
import { ValidatorFn } from '@angular/forms';

interface Sizeable {
  gridClass?: string; // col-1, col-2, ... etc
  padding?: string[]; // ['4px', '8px', '4px', '8px'], ['4px', '8px'], ['auto']
  margin?: string[]; // ['4px', '8px', '4px', '8px'], ['4px', '8px'], ['auto'],
  classes?: string[];
}

interface Alignable {
  alignment?: 'center' | 'left' | 'right';
}

interface Hideable {
  hidden?: () => boolean;
}

interface Changeable {
  change?: (...args: any) => void;
}

interface Clickable {
  click?: (...args: any) => void;
}

export enum MoodRendererUnitType {
  BUTTON,
  CHECKBOX,
  MULTI_CHECKBOX,
  RADIO_GROUP,
  PARAGRAPH,
  INPUT,
  TEXTAREA,
  ACCORDION,
  DROPDOWN,
  BUTTON_GROUP,
  HEADER,
  CAPTCHA,
  MULTI_SELECT,
  DATE_PICKER,
  CODE_DISPLAY,
  BLOCK,
}

export interface FormData {
  controlName: string;
  controlType?: string;
  valueType?: string; // todo(aleksanderbodurri): remove
  currentValue?: string | any[];
  placeholder?: string | (() => string);
  mask?: string; // for text inputs
  unmaskInput?: boolean;
  hasGroups?: boolean; // for multi-checkbox
  options?: Array<{
    label: string | (() => string);
    value: string | boolean;
    group?: string | boolean; // for multi-checkbox
    html?: string | (() => string);
  }>;
  validators?: {
    required?: boolean;
    minlength?: number;
    maxlength?: number;
    pattern?: string | RegExp; // does not work if input mask exists
    validatorFunctions?: ValidatorFn[];
  };
  autocomplete?: string;
}

export interface HasForm {
  formData?: FormData;
}

export interface MoodButtonRenderUnit extends Sizeable, Alignable, Hideable, Clickable {
  type: MoodRendererUnitType.BUTTON;
  buttonClasses: string[];
  disabled?: boolean | (() => boolean);
  text: string | (() => string);
}

export interface MoodButtonGroupRenderUnit extends Sizeable, Alignable, Hideable, Clickable {
  type: MoodRendererUnitType.BUTTON_GROUP;
  buttons: MoodButtonRenderUnit[];
}

export interface MoodCheckboxRenderUnit extends Sizeable, Changeable, HasForm {
  type: MoodRendererUnitType.CHECKBOX;
  value: any;
  checked: boolean;
  label: string | (() => string);
}

export interface MoodMultiCheckboxRenderUnit extends Sizeable, Changeable, HasForm {
  type: MoodRendererUnitType.MULTI_CHECKBOX;
  value: any[];
  checked: boolean;
  label: string | (() => string);
}

export interface MoodRadioRenderUnit extends Sizeable, HasForm, Hideable, Changeable {
  type: MoodRendererUnitType.RADIO_GROUP;
  radioButtons: MoodRadioButton[];
  selected: MoodRadioButton;
  label: string | (() => string);
}

export interface MoodParagraphRenderUnit extends Sizeable, HasForm, Hideable {
  type: MoodRendererUnitType.PARAGRAPH;
  html: string | (() => string);
}

export interface MoodHeaderRenderUnit extends Sizeable, Hideable {
  type: MoodRendererUnitType.HEADER;
  label: string | (() => string);
}

export interface MoodAccordionRenderUnit extends Sizeable, HasForm, Hideable {
  type: MoodRendererUnitType.ACCORDION;
  expanded: boolean;
  header: string | (() => string);
  content: MoodRenderFrame;
}

export interface MoodInputRenderUnit extends Sizeable, HasForm, Changeable, Hideable, Alignable {
  type: MoodRendererUnitType.INPUT;
  placeholder?: string;
  label: string | (() => string);
  disabled?: boolean | (() => boolean);
  value: string;
  helpText?: string | (() => string);
}

export interface MoodTextAreaRenderUnit extends Sizeable, HasForm, Changeable, Hideable, Alignable {
  type: MoodRendererUnitType.TEXTAREA;
  placeholder?: string;
  label: string | (() => string);
  value: string;
  maxLength?: number;
  helpText?: string | (() => string);
}

export interface MoodDropdownRenderUnit extends HasForm, Alignable, Sizeable, Hideable {
  type: MoodRendererUnitType.DROPDOWN;
  label: string | (() => string);
}

export interface MoodCaptcha extends Alignable, Sizeable, Hideable {
  type: MoodRendererUnitType.CAPTCHA;
  siteKey: string;
  onResolved: (...args) => void;
}

export interface MoodMultiSelectRenderUnit extends HasForm, Alignable, Sizeable, Hideable {
  type: MoodRendererUnitType.MULTI_SELECT;
  label: string | (() => string);
}

export interface MoodDatePicker extends HasForm, Alignable, Sizeable {
  type: MoodRendererUnitType.DATE_PICKER;
}

export interface MoodCodeDisplayRenderUnit extends Sizeable, Hideable {
  type: MoodRendererUnitType.CODE_DISPLAY;
  value: string | (() => string);
  label: string | (() => string);
}

export interface MoodBlockRenderUnit {
  type: MoodRendererUnitType.BLOCK;
  html: string;
  classes: string[];
}

export type MoodRendererUnit = Partial<
  | MoodButtonRenderUnit
  | MoodRadioRenderUnit
  | MoodAccordionRenderUnit
  | MoodInputRenderUnit
  | MoodTextAreaRenderUnit
  | MoodParagraphRenderUnit
  | MoodCheckboxRenderUnit
  | MoodMultiCheckboxRenderUnit
  | MoodDropdownRenderUnit
  | MoodButtonGroupRenderUnit
  | MoodHeaderRenderUnit
  | MoodCaptcha
  | MoodMultiSelectRenderUnit
  | MoodDatePicker
  | MoodCodeDisplayRenderUnit
  | MoodBlockRenderUnit
>;

export type MoodRenderFrame = Array<MoodRendererUnit>;
export type MoodRendererInstructions = Array<MoodRenderFrame>;

export const MoodMask = {
  phone: (type: 'delimited' | 'areaCodeHighlighted', countryCode: boolean = true) => {
    const phoneSegments = ['999', '999', '9999'];

    if (type === 'delimited') {
      return countryCode ? '9-' + phoneSegments.join('-') : phoneSegments.join('-');
    }

    if (type === 'areaCodeHighlighted') {
      const mask = `(${phoneSegments[0]}) ${phoneSegments[1]}-${phoneSegments[2]}`;
      return countryCode ? `9 ${mask}` : mask;
    }
  },
  date: () => '99/99/9999',
  postal: () => 'a9a9a9',
  shortPostal: () => 'a9a',
};

export const MoodRegexValidator = {
  not: (value: string) => `((?!${value}).)*`,
  phoneNumber: () =>
    /^(?:\+?[1-9]\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/,
  email: () =>
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  ontarioPostal: () => /^[KLMNPklmnp]\d[A-Za-z]\d[A-Za-z]\d$/,
  ontarioPostalArea: () => /^[KLMNPklmnp]\d[A-Za-z]$/,
  healthcard: () => /^[0-9]{10}[A-Z]{0,2}$/,
};
