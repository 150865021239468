// Angular
import { Component } from '@angular/core';

// Components
import { FormContainerComponent } from '@shared/components/form-container/form-container.component';

// Models
import { InvisibleContainer } from '@shared/models/invisible-container';

@Component({
  selector: 'app-horizontal-container',
  templateUrl: './invisible-container.component.html',
  styleUrls: ['./invisible-container.component.scss'],
})
export class InvisibleContainerComponent extends FormContainerComponent {
  item: InvisibleContainer;
}
