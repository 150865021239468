<label *ngIf="!form; else withForm" class="container-input">
  <input
    *ngIf="!mask; else inputMask"
    type="text"
    (input)="onInput.emit(value)"
    [placeholder]="inputPlaceHolder"
    [(ngModel)]="value"
    [disabled]="disabled"
    (input)="valueChange($event)"
    class="input-field"
  />
  <ng-template #inputMask>
    <p-inputMask
      [mask]="mask"
      (input)="onInput.emit(value)"
      [placeholder]="inputPlaceHolder"
      [(ngModel)]="value"
      [disabled]="disabled"
    >
    </p-inputMask>
  </ng-template>
  <div *ngIf="error" class="alert alert-danger error-alert" role="alert">
    {{ error }}
  </div>
</label>

<ng-template #withForm>
  <label class="container-input" [formGroup]="form">
    {{ inputLabel }}
    <input
      [formControlName]="formData.controlName"
      [type]="formData.controlType"
      [name]="formData.controlName"
      [placeholder]="inputPlaceHolder"
      [minlength]="formData.validators.minlength"
      [maxlength]="formData.validators.maxlength"
      [required]="formData.validators.required"
      [pattern]="formData.validators.pattern"
      (input)="valueChange($event)"
      [disabled]="disabled"
      *ngIf="!formData.mask; else inputMask"
    />
    <ng-template #inputMask>
      <p-inputMask
        [formControlName]="formData.controlName"
        [name]="formData.controlName"
        [minlength]="formData.validators.minlength"
        [maxlength]="formData.validators.maxlength"
        [required]="formData.validators.required"
        [placeholder]="inputPlaceHolder"
        [pattern]="formData.validators.pattern"
        [autoClear]="false"
        [autocomplete]="formData.autocomplete"
        [unmask]="formData.unmaskInput === undefined ? true : formData.unmaskInput"
        [mask]="formData.mask"
        [disabled]="disabled"
        [type]="formData.controlType"
      ></p-inputMask>
    </ng-template>
    <small *ngIf="helpText" [innerHTML]="inputHelpText"></small>
  </label>
</ng-template>
