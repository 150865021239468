<div class="p-grid p-align-end p-fluid" *ngFor="let children of formElementsArray; let i = index">
  <div class="p-col-1 no-width" *ngIf="item.showIndex">
    <p>{{ i + 1 }}.</p>
  </div>
  <app-dynamic-component
    *ngFor="let child of children"
    class="child-item p-col-{{ child.getColSpan() }} {{ item.id }}"
    [item]="child"
    [class.no-padding]="child.elementTypeName.includes('Container') || child.hidden"
    [class.hidden]="child.hidden"
    [extraOptions]="{ index: i + 1 }"
  >
  </app-dynamic-component>
  <div class="p-col-1" *ngIf="item.minItems < i + 1">
    <button
      pButton
      type="button"
      class="p-button-danger"
      icon="pi pi-times"
      (click)="deleteField(i)"
      tabindex="-1"
      [disabled]="disabled"
    ></button>
  </div>
</div>
<button
  *ngIf="item.maxItems > formArray.controls.length"
  pButton
  class="p-button-secondary"
  type="button"
  [label]="item.addButtonLabel"
  (click)="addField()"
  [disabled]="disabled"
></button>
