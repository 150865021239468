<p-inputMask
  ngDefaultControl
  [id]="item.id"
  [placeholder]="item.placeholder"
  [(ngModel)]="value"
  [mask]="item.mask"
  [slotChar]="item.slotChar"
  [autoClear]="item.autoClear"
  [disabled]="disabled"
></p-inputMask>
