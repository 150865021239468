// angular
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

// models
import {
  FormContainer,
  FormElement,
  RepeatableContainer,
} from '@shared/models';
import { FormComponent } from '@shared/models/form-component';
import { FormElementImpl } from '@shared/models/form-element-impl';
import { FormLayout } from '@shared/models/form-layout';

// rxjs
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-repeatable-container-control',
  templateUrl: './repeatable-container-control.component.html',
  styleUrls: ['./repeatable-container-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RepeatableContainerControlComponent),
      multi: true,
    },
  ],
})
export class RepeatableContainerControlComponent
  implements ControlValueAccessor, OnInit
{
  // Three important attributes to keep in mind that powers this component
  // _values: This holds the data. (list of values to output)
  // formArray: This holds the FormControls for this component. This has no relationship with the RootContainer's FormControls.
  // formElementsArray: This holds the FormElements relating to the FormControl above.

  @Input() item: RepeatableContainer;

  // form-control attributes
  onChange: CallableFunction;
  onTouched: CallableFunction;
  private _values: object[] = [];

  // disable logic
  private _disabled: boolean;
  get disabled(): boolean {
    if (this._disabled) {
      return this._disabled;
    }

    // VSD-3334: when there are any children fields that are disabled, disable this form control
    return this.item.children.some((child) => {
      return child instanceof FormComponent ? child.readonly : false;
    });
  }
  set disabled(status: boolean) {
    this._disabled = status;
  }

  // custom attributes
  private subscriber: Subscription;
  formArray: UntypedFormArray = new UntypedFormArray([]);

  // this is a complement to formArray to have a cache of the FormElements
  // inner array `n` will store FormElements relating to `formArray.get(n)`.
  // Ensure to keep this in sync at all times with formArray
  formElementsArray: FormElement[][] = [];
  syncFormArray() {
    // rerender everything. We don't mind this as a FormArray can only be synced when the user clicks add new or delete.
    this.formElementsArray = [];
    this.formArray.controls.forEach((control) => {
      this.formElementsArray.push(this.clonedChildren(control));
    });
  }

  ngOnInit() {
    if (this.item.minItems > this.values.length) {
      for (let i = 0; i < this.item.minItems - this.values.length; i++) {
        this.addField();
      }
    }
  }

  get values() {
    return this._values;
  }

  set values(values: any[]) {
    this._values = values || [];
    this.notifyValuesChanged();
    // Hack by Yifei: this is a way to make it work
    // For some reason, the actual value is stored in the repeatable-container-control.component
    // as oppose to repeatable-container.
    // The value extraction process only has access to the model, and not the component,
    // so we need a way to access the value inside the component from the model

    // This must be done inside this function, after this.notifyValuesChanged() call.
    // Attempt 1:
    // this.item.repeatableContainerModelRef = this;
    // This causes a security issue Blocked a frame with origin from accessing a cross-origin frame
    // Not sure why it happens. Did not investigate much further
    // Attempt 2:
    // this.item.currentValue = this.item.formControl.value; OR
    // this.item.currentValue = this._values;
    // The value is not updated beyond initial value
    this.item.getValue = () => this.item.formControl.value;
  }

  constructor() {}

  private notifyValuesChanged(): void {
    if (this.onChange) {
      this.onChange(this.values);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.formArray.disable();
    } else {
      this.formArray.enable();
    }
  }

  registerOnChange(fn: CallableFunction): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: CallableFunction): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.formArray.reset();
      return;
    }

    if (!Array.isArray(obj)) {
      obj = Object.values(obj);
    }

    this.values = obj;
    this.createFormArray(obj);
    this.syncFormArray();
  }

  addField() {
    const rawFields = {};
    const formFields = {};

    for (const child of this.item.children) {
      if (!(child instanceof FormLayout)) {
        formFields[child.formName()] = child.toFormControl();
        rawFields[child.formName()] = '';
      }
    }

    const formGroup: UntypedFormGroup = new UntypedFormGroup(formFields);
    this.resetSubscriber();

    // add values and notify
    this._values.push(rawFields);
    this.formArray.push(formGroup);
    this.syncFormArray();
    this.notifyValuesChanged();
  }

  deleteField(i: number): void {
    this._values.splice(i, 1);
    this.formArray.removeAt(i);
    this.notifyValuesChanged();
    this.syncFormArray();
  }

  // creates all FormElements for a new AbstractControl in FormArray.
  clonedChildren(fg: AbstractControl): FormElementImpl[] {
    return this.item.children.map((child) =>
      this.cloneNewItem(child, fg.get(child.formName()))
    );
  }

  cloneNewItem(item: FormElement, control: AbstractControl): FormElementImpl {
    const newItem = item.clone() as FormElementImpl;
    newItem.formControl = control;
    // the formControl here is used exclusively for this RepeatableContainer and is not part of the main FormControl in RootContainer.
    if (
      !(newItem instanceof RepeatableContainer) &&
      newItem instanceof FormContainer
    ) {
      this.assignControlsToChildren(newItem, newItem.formControl);
    }

    return newItem;
  }

  assignControlsToChildren(
    container: FormContainer,
    abstractControl: AbstractControl
  ) {
    for (const child of container.children) {
      if (child instanceof RepeatableContainer) {
        child.formControl = abstractControl.get(child.formName());
      } else if (child instanceof FormContainer) {
        this.assignControlsToChildren(
          child,
          abstractControl.get(child.formName())
        );
      } else {
        child.formControl = abstractControl.get(child.formName());
      }
    }
  }

  private createFormArray(values: object[]): void {
    // map values into desired format
    this.formArray = new UntypedFormArray(
      values.map((repeat: { [formName: string]: any }) => {
        const fields = {};
        for (const child of this.item.children) {
          if (
            child instanceof FormComponent ||
            child instanceof RepeatableContainer
          ) {
            fields[child.formName()] = child.toFormControl(
              repeat[child.formName()]
            );
          } else if (child instanceof FormContainer) {
            const formControl = child.toFormControl();
            formControl.patchValue(repeat[child.formName()]);
            fields[child.formName()] = formControl;
          }
        }
        return new UntypedFormGroup(fields);
      })
    );
    this.resetSubscriber();
  }

  private resetSubscriber(): void {
    // subscribe to changes
    if (!!this.subscriber) {
      this.subscriber.unsubscribe();
    }
    // push changes to values
    this.subscriber = this.formArray.valueChanges.subscribe(
      () => (this.values = this.formArray.getRawValue())
    );
  }
}
