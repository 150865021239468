<p-calendar
  ngDefaultControl
  [(ngModel)]="value"
  [dateFormat]="item.dateFormat"
  [minDate]="item.minDate"
  [maxDate]="item.maxDate"
  [showButtonBar]="item.showButtonBar"
  [selectionMode]="item.selectionMode"
  [readonlyInput]="disabled"
  [showTime]="item.showTime"
  [timeOnly]="item.timeOnly"
  [disabled]="disabled"
  [monthNavigator]="true"
  [yearNavigator]="true"
  [yearRange]="item.yearRange"
  [locale]="localeData"
  (onInput)="this.onInput.next(value)"
  (onSelect)="this.onInput.next(value)"
></p-calendar>
