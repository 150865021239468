// angular
import { Component, Input } from '@angular/core';

// models
import {
  FormContainer,
  FormElement,
  RepeatableContainer,
} from '@shared/models';

// services
import { FormEventService } from '@root/services/form-event.service';
import { FormUpdateService } from '@root/services/form-update.service';
import { FormGroupService } from '@root/services/form-group.service';
import { FormComponent } from '@root/shared/models/form-component';

@Component({ template: '' })
export class FormElementComponent {
  @Input() item: FormElement;
  @Input() buildable: boolean = false;
  private fieldId: string;
  private repeatableRoot?: RepeatableContainer;

  get isRootNode() {
    return !this.repeatableRoot;
  }

  constructor(
    private fgs: FormGroupService,
    private fus: FormUpdateService,
    public fes: FormEventService
  ) {}

  ngOnInit() {
    /**
     * We don't support updating individual elements inside repeatable container. The entire
     * value will be emitted from the repeatable container and any repeatable container needs
     * to be updated as a whole too. See documentation of collaborative form editing for current
     * limitations of the feature.
     * Thus, we need to find the farthest/largest repeatable container that contains this element
     * in order to find {@link this.fieldId} or update {@link this.item.formControl.value} in the future.
     * Let's store the {@link this.repeatableRoot} here so we don't need to do it every time
     * we need to emit the event. We also derive {@link this.isRootNode} from it.
     */
    this.repeatableRoot = findFarthestRepeatableContainer(this.item.parent);
    this.fieldId = getFieldId(this.repeatableRoot || this.item);
    // Only subscribe if we are at a root node
    if (this.isRootNode) {
      this.fus.fieldSubject(this.fieldId).subscribe((update) => {
        // === Beginning of validation ===
        const expectedKeys = ['value', 'readonly', 'extraText'];
        for (const key of Object.keys(update)) {
          console.assert(
            expectedKeys.includes(key),
            key,
            'is not one of',
            expectedKeys
          );
        }
        console.assert(
          Object.keys(update).length > 0,
          'Expecting non-empty update object'
        );
        // === End of validation ===

        if (update.value !== undefined) {
          this.updateValue(update.value);
        }

        if (update.readonly !== undefined) {
          if (update.readonly) {
            this.item.formControl.disable({ emitEvent: false });
          } else {
            this.item.formControl.enable({ emitEvent: false });
          }
        }

        if (update.extraText !== undefined) {
          const component = this.item as FormComponent;
          component.label = addTextWithHiddenMarker(
            component.label,
            update.extraText
          );
        }
      });
    }
  }

  updateValue(value: any): void {
    this.item.formControl.patchValue(value);
  }

  emitEvent() {
    this.fes.formEventSubject.next({
      eventType: 'onChange',
      data: {
        fieldId: this.fieldId,
        newValue: this.repeatableRoot
          ? this.repeatableRoot.getValue()
          : this.item.formControl.value,
      },
    });

    if (this.item.onChangeEventName) {
      this.fes.formEventSubject.next({
        eventType: this.item.onChangeEventName,
        data: this.item,
      });
    }
  }
}

function findFarthestRepeatableContainer(
  container: FormContainer
): RepeatableContainer | null {
  let current = container;
  let farthestRepeatableContainer = null;

  while (current) {
    if (current.elementTypeName === 'RepeatableContainer') {
      farthestRepeatableContainer = current;
    }
    current = current.parent;
  }

  return farthestRepeatableContainer;
}

function getFieldId(formElement: FormElement): string {
  const elementName = formElement.formName();
  const containerNames = getContainerNames(formElement.parent);
  return JSON.stringify([...containerNames, elementName]);
}

function getContainerNames(formContainer: FormContainer): string[] {
  if (formContainer.elementTypeName === 'RootContainer') return [];
  if (formContainer.affectLayout)
    return [
      ...getContainerNames(formContainer.parent),
      formContainer.formName(),
    ];
  else return [...getContainerNames(formContainer.parent)];
}

/**
 * Add, update, or remove extra text in label
 * @param currentLabel the current label string
 * @param extraText extraText we want to add, null if we want to remove the extraText
 * @returns updated string
 *
 *
 * We want to append extra text to the label of the component, indicating the
 * collaborative form editing state for that field.
 *
 * This "extra text" is something like
 *                    vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
 * Patient First Name (Locking...)
 * Patient First Name (Locked by Verto Support)
 * Patient First Name (Saving...)
 * Patient First Name (Error: unable to save)
 *                    ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 * We also want to remove or update this text when the state changes.
 * The proper way to do it is to add a collaborative form editing state to
 * {@link FormElement} or {@link FormComponent}, and come up with a way to convey
 * that information to user, which could be inside the label (the same as the
 * current approach), or through color (background/outline/etc color, since
 * not all elements have label, {@link FormContainer} in particular).
 *
 * Currently we just allow the parent frame to append an "extra text" to any element.
 * This VertoForms implementation abuses the label field to achieve "storing the
 * original text without creating a new variable".
 *
 * We enforce that the extra text must appear after the original text. And we add
 * a hidden character (zero-width space, ZWSP) in between the original and extra text.
 * This way we can easily and reliably restore the original text.
 */
function addTextWithHiddenMarker(
  currentLabel: string,
  extraText: string | null
) {
  const ZERO_WIDTH_SPACE = '\u200b';
  let originalString: string;

  if (!currentLabel.includes(ZERO_WIDTH_SPACE)) {
    originalString = currentLabel;
  } else {
    console.assert(currentLabel.split(ZERO_WIDTH_SPACE).length === 2);
    originalString = currentLabel.split(ZERO_WIDTH_SPACE)[0];
  }

  if (extraText === null) return originalString;

  return originalString + ZERO_WIDTH_SPACE + ` (${extraText})`;
}
