// Angular
import { Component } from '@angular/core';

// Components
import { ListFormComponentComponent } from '@shared/components/list-form-component/list-form-component.component';

// Models
import { MultiSelect } from '@shared/models/multi-select';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent extends ListFormComponentComponent {
  item: MultiSelect;
}
