import { Component, Input } from '@angular/core';
import { valOrEval } from '../mood-utils/val-or-eval';

@Component({
  selector: 'mood-accordion',
  templateUrl: './mood-accordion.component.html',
  styleUrls: ['./mood-accordion.component.scss'],
})
export class MoodAccordionComponent {
  @Input() header: string;
  @Input() expanded: boolean;

  get accordionHeader() {
    return valOrEval(this.header);
  }
}
