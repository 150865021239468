// angular
import { Component } from '@angular/core';

// components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// models
import { Spacing } from '@shared/models/spacing';

@Component({
  selector: 'app-spacing',
  templateUrl: './spacing.component.html',
  styleUrls: ['./spacing.component.scss'],
})
export class SpacingComponent extends FormElementComponent {
  item: Spacing;
}
