// Angular
import { Component } from '@angular/core';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { InputMask } from '@shared/models';

@Component({
  selector: 'app-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss'],
})
export class InputMaskComponent extends FormElementComponent {
  item: InputMask;
}
