import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormData } from 'engage-common';
import { Dropdown } from 'primeng/dropdown';
import { valOrEval } from '../mood-utils/val-or-eval';

@Component({
  selector: 'mood-dropdown',
  templateUrl: './mood-dropdown.component.html',
  styleUrls: ['./mood-dropdown.component.scss'],
})
export class MoodDropdownComponent implements OnChanges {
  @Input() label: string;
  @Input() options: { value: string; label: string }[];

  @Input() selected: any;
  @Output() selectedChange = new EventEmitter();

  @Input() form: UntypedFormGroup;
  @Input() formData: FormData;

  @Output() dropdownValueChange = new EventEmitter();

  @ViewChild(Dropdown) innerDropdown: Dropdown;

  valueChange($event: any) {
    this.selectedChange.emit({
      label: $event.originalEvent.target.textContent,
      value: $event.value,
    });

    this.formData.currentValue = $event.value;

    this.dropdownValueChange.emit($event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (this.innerDropdown && this.selected) {
        this.innerDropdown.selectedOption = this.selected;
      }
    });
  }

  get inputLabel(): string {
    return valOrEval(this.label);
  }
}
