<div class="p-label" [attr.data-name]="item.name">
  <label [htmlFor]="item.id">{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label>
  <div class="p-grid" style="margin-top: 7px">
    <div
      class="checkbox-option"
      *ngFor="let option of options"
      [ngClass]="[item.horizontal ? 'p-col-' + item.colspanPerItem : 'p-col-12']"
    >
      <p-checkbox
        [name]="item.id"
        [value]="option.value"
        [label]="option.label"
        [formControl]="item.formControl"
        [inputId]="item.id"
        [binary]="item.binary"
        (onChange)="emitEvent()"
      ></p-checkbox>
    </div>
  </div>
</div>
<app-form-validators [item]="item"></app-form-validators>
