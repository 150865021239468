<div class="p-fluid" [attr.data-name]="item.name">
  <div class="p-label">
    <label [htmlFor]="item.id">{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label>
    <app-patch-calendar
      [id]="item.id"
      [item]="item"
      [formControl]="item.formControl"
      [localeData]="localeData()"
      (onInput)="emitEvent()"
    ></app-patch-calendar>
  </div>
  <app-form-validators [item]="item"></app-form-validators>
</div>
