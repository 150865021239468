import { FormContainer } from '@shared/models/form-container';
import { FieldsetOptions } from '@shared/options';

export class Fieldset extends FormContainer {
  elementTypeName: string = 'Fieldset';

  legend: string;
  toggleable: boolean;
  collapsed: boolean;

  constructor(options: FieldsetOptions) {
    super(options);
    this.legend = options.legend;
    this.toggleable = options.toggleable;
    this.collapsed = options.collapsed;
  }

  toJSON(): object {
    return Object.assign(super.toJSON(), {
      legend: this.legend,
      toggleable: this.toggleable,
      collapsed: this.collapsed,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'legend',
          label: 'Legend',
          required: true,
          placeholder: 'Legend',
        },
        {
          elementType: 'InputSwitch',
          name: 'collapsed',
          label: 'Collapsed By Default',
          required: false,
          default: false,
        },
        {
          elementType: 'InputSwitch',
          name: 'toggleable',
          label: 'Toggleable',
          required: false,
          default: false,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
