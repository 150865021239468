// Angular
import { Component } from '@angular/core';

// Component
import { FormContainerComponent } from '@shared/components/form-container/form-container.component';

// Models
import { Fieldset } from '@shared/models';

@Component({
  selector: 'app-fieldset',
  templateUrl: './fieldset.component.html',
  styleUrls: ['./fieldset.component.scss'],
})
export class FieldsetComponent extends FormContainerComponent {
  item: Fieldset;
}
