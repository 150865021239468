import { FormLayout } from '@shared/models/form-layout';
import { MarkdownTextOptions } from '@shared/options';

export class MarkdownText extends FormLayout {
  elementTypeName: string = 'MarkdownText';

  // markdown formatted text
  markdownText: string;

  constructor(options: MarkdownTextOptions) {
    super(options);
    this.markdownText = options.markdownText;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      markdownText: this.markdownText,
    });
  }

  getEditFormJSON(extraChildren: object[] = []) {
    return super.getEditFormJSON([
      {
        elementType: 'InputTextarea',
        label: 'Markdown Text',
        colspan: 12,
        name: 'markdownText',
        rows: 2,
        cols: 20,
        autoResize: true,
        resizable: false,
      },
    ]);
  }
}
