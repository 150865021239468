<ng-container *ngIf="mode === 'drag'">
  <div id="trash" cdkDropList (cdkDropListDropped)="builder.onFormElementTrash($event)">Drop trash here</div>
  <br />
  <p-accordion class="drop-shadow-theme">
    <p-accordionTab
      *ngFor="let item of palette; let i = index"
      [header]="item.section"
      class="toolbar-items"
      cdkDropList
      [cdkDropListConnectedTo]="draggableElementIds"
      [cdkDropListData]="item"
      [selected]="i === 0"
    >
      <div
        *ngFor="let toolInfo of item.tools"
        class="toolbar-item"
        cdkDrag
        [cdkDragData]="toolInfo.tool"
        (cdkDragStarted)="onDragOut()"
      >
        {{ toolInfo.name }}
        <div *cdkDragPlaceholder class="toolbar-item p-col-{{ toolInfo.tool.getColSpan() }}">
          {{ toolInfo.name }}
        </div>
        <div class="toolbar-item-handle"></div>
      </div>
    </p-accordionTab>
  </p-accordion>
</ng-container>

<ng-container *ngIf="mode === 'select'">
  <div class="select-mode">
    <p-accordion class="drop-shadow-theme" [multiple]="true">
      <p-accordionTab
        *ngFor="let item of palette; let i = index"
        [header]="item.section"
        class="toolbar-items"
        [selected]="i === 0"
      >
        <ng-template pTemplate="content">
          <div *ngFor="let toolInfo of item.tools" class="toolbar-item">
            <button class="w-100" pButton (click)="componentSelected.emit(toolInfo.tool)">
              {{ toolInfo.name }}
            </button>
          </div>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
</ng-container>
