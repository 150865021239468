<form
  *ngIf="!!renderedForm && !!componentTree"
  [formGroup]="renderedForm"
  class="form-root"
  [ngClass]="componentTree.themeClass"
  (submit)="submit()"
  (resized)="onResized($event)"
>
  <app-root-container [item]="componentTree" [buildable]="buildable"></app-root-container>
</form>
