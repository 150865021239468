import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EngageUtilsConfig } from './engage-utils-config';
import { EngageSerializableStoreService } from './engage-serializable-store.service';

@Injectable()
export class EngageApiResponseInterceptor implements HttpInterceptor {
  constructor(
    private config: EngageUtilsConfig,
    private _router: Router,
    private _store: EngageSerializableStoreService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.config.apiInterceptor) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (
          err.status === 401 &&
          this.config?.apiInterceptor?.errorRedirects?.['401']?.redirectTo
        ) {
          this._store.clear(); // defensive programming to prevent expired tokens from lingering and messing with redirects
          const currentPath = this._router.url.split('?')[0].replace('/', '');
          if (!this.config.apiInterceptor.errorRedirects['401'].exclude?.includes(currentPath)) {
            this._router.navigate(this.config.apiInterceptor.errorRedirects['401'].redirectTo);
          }
        }

        return throwError(err);
      })
    );
  }
}
