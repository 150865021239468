import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss'],
})
export class ScreenComponent {
  @Input() progressBarEnabled = false;
  @Input() page = -1;
  @Input() totalPages = -1;
  @Input() disableDrawerButton = false;
}
