// Angular
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Models
import { InputMask } from '@shared/models';

@Component({
  selector: 'app-patch-input-mask',
  templateUrl: './patch-input-mask.component.html',
  styleUrls: ['./patch-input-mask.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatchInputMaskComponent),
      multi: true,
    },
  ],
})
export class PatchInputMaskComponent implements ControlValueAccessor {
  /**
   * PrimeNG's InputMask does not work with FormControl properly.
   * This component patches the functionality and populates the FormControl properly.
   *
   * https://www.primefaces.org/primeng/#/calendar
   */

  @Input() item: InputMask;
  @Output() onChangeCallback: EventEmitter<void> = new EventEmitter();

  _value: string;

  disabled: boolean;

  onTouched: CallableFunction;
  onChange: CallableFunction;

  constructor() {}

  set value(val: string) {
    this._value = val;
    if (this.onChange) {
      this.onChange(val);
    }
    if (this.onTouched) {
      this.onTouched(val);
    }

    this.onChangeCallback.next();
  }

  get value() {
    return this._value;
  }

  registerOnChange(fn: CallableFunction): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: CallableFunction): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(val: string): void {
    if (!val) {
      this.value = undefined;
      return;
    }
    this.value = val;
  }
}
