// models
import { FormContainer } from '@shared/models/form-container';
import { AccordionOptions } from '@shared/options';

export class Accordion extends FormContainer {
  elementTypeName: string = 'Accordion';

  header: string;
  lazyLoad: boolean;
  defaultOpen: boolean;

  constructor(options: AccordionOptions) {
    super(options);
    this.header = options.header;
    this.lazyLoad = options.lazyLoad;
    this.defaultOpen = options.defaultOpen;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      header: this.header,
      lazyLoad: this.lazyLoad,
      defaultOpen: this.defaultOpen,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'header',
          label: 'Header',
          required: true,
          placeholder: 'Header',
        },
        {
          elementType: 'InputSwitch',
          name: 'defaultOpen',
          label: 'Open By Default',
          required: false,
          default: false,
        },
        {
          elementType: 'InputSwitch',
          name: 'lazyLoad',
          label: 'Lazy load',
          required: false,
          default: false,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
