// models
import { InputTextOptions } from '@shared/options';
import { FormComponent } from './form-component';

export class InputText extends FormComponent {
  elementTypeName: string = 'InputText';

  pKeyFilter: string | RegExp;
  hidden: boolean;
  inputType: string;

  placeholder: string;
  maxlength: string;

  constructor(options: InputTextOptions) {
    super(options);
    this.pKeyFilter = options.pKeyFilter || /[\s\S]*/;
    this.hidden = options.hidden;
    this.inputType = options.inputType || 'text';

    this.placeholder = options.placeholder || '';
    this.maxlength = options.maxlength;
  }

  toJSON(): object {
    return Object.assign(super.toJSON(), {
      pKeyFilter: this.pKeyFilter.toString(),
      inputType: this.inputType,
      placeholder: this.placeholder,
      maxlength: this.maxlength,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'inputType',
          label: 'Input Type',
          required: false,
        },
        {
          elementType: 'InputText',
          name: 'placeholder',
          label: 'Placeholder',
          required: false,
        },
        {
          elementType: 'InputText',
          name: 'maxlength',
          label: 'Character Limit',
          inputType: 'number',
        },
        {
          elementType: 'Dropdown',
          name: 'pKeyFilter',
          label: 'pKeyFilter',
          colspan: 12,
          required: false,
          options: [
            { label: 'Positive Integers', value: 'pint' },
            { label: 'Integers', value: 'int' },
            { label: 'Positive numbers', value: 'pnum' },
            { label: 'Numbers', value: 'num' },
            { label: 'Email', value: 'email' },
            { label: 'Alphabetic', value: 'alpha' },
            { label: 'Alphanumeric', value: 'alphanum' },
          ],
          filterBy: 'label',
          placeholder: 'Select a filter',
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
