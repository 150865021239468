import { FormComponent } from '@shared/models/form-component';
import { FileUploadOptions } from '@shared/options';

export class FileUpload extends FormComponent {
  elementTypeName: string = 'FileUpload';

  accept: string;
  multiple: boolean;

  constructor(options: FileUploadOptions) {
    super(options);
    this.accept = options.accept;
    this.multiple = options.multiple;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      accept: this.accept,
      multiple: this.multiple,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputSwitch',
          name: 'multiple',
          label: 'Multiple files?',
          required: false,
          default: false,
        },
        {
          elementType: 'InputText',
          name: 'accept',
          label: 'Accepted filetypes',
          required: false,
          default: false,
          placeholder: 'eg. image/*',
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
