// Angular
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// RxJS
import { debounceTime, first, skip, tap } from 'rxjs/operators';

// Resizing
import { ResizedEvent } from './angular-resize-event/resize.directive';

// Services
import { BuilderService } from '@root/services/builder.service';
import { FormActionService } from '@root/services/form-action.service';
import { FormEventService } from '@root/services/form-event.service';
import { FormGroupService } from '@root/services/form-group.service';
import { FormParser } from '@root/services/form-parser';

// Models
import { RootContainer } from '@shared/models/root-container';
import { Subscription } from 'rxjs';
import { FormStateService } from '@root/services/form-state.service';

@Component({
  selector: 'app-renderer',
  templateUrl: './renderer.component.html',
  styleUrls: ['./renderer.component.scss'],
  providers: [FormGroupService, FormActionService],
})
export class RendererComponent implements OnInit, AfterViewInit, OnDestroy {
  componentTree: RootContainer;
  renderedForm: UntypedFormGroup;

  @Input() debounceFormTime = 1500;

  /**
   * If this flag is true, the form does not output
   * when an form input changes unless the form is valid. If this flag is false
   * the form outputs every change.
   */
  @Input() requiredValid = true;

  @Output() submission: EventEmitter<object> = new EventEmitter();
  @Output() formState: EventEmitter<'not-saved' | 'error'> = new EventEmitter();

  @Output() formTreeChanged: EventEmitter<UntypedFormGroup> =
    new EventEmitter<UntypedFormGroup>();
  @Output() componentTreeChanged: EventEmitter<RootContainer> =
    new EventEmitter<RootContainer>();

  @Input() buildable: boolean = false;
  @Input()
  set formJson(formJson: object) {
    // parse the component tree and formGroup
    const componentTree = this.formParser.parseComponentTree(
      formJson
    ) as RootContainer;
    this.renderedForm = componentTree.toFormControl() as UntypedFormGroup;

    this.componentTree = componentTree;
    // emit objects out
    this.componentTreeChanged.emit(this.componentTree);
    this.fgService.updateFormGroup(this.renderedForm);
  }

  @Input()
  set inputValues(inputValues: object) {
    // unsubscribe after once
    this.fgService
      .getFormGroup()
      .pipe(first())
      .subscribe((formGroup: UntypedFormGroup) => {
        // prefill values in formGroup with valuesJSON
        setTimeout(() => {
          formGroup.patchValue(inputValues || {});
        });
      });
  }

  constructor(
    private formParser: FormParser,
    public builder: BuilderService,
    private fgService: FormGroupService,
    private feService: FormEventService,
    private faService: FormActionService,
    private fsService: FormStateService
  ) {}

  subscriptions: Subscription[] = [];

  ngOnInit(): void {
    // whenever the formGroup in service changes, emit to implementer
    this.subscriptions = [
      ...this.subscriptions,
      this.fgService.getFormGroup().subscribe((formGroup: UntypedFormGroup) => {
        this.formTreeChanged.emit(formGroup);
        // Note: read only mode will break when two form controls share the same name.
        // This is behavior from Angular reactive forms, and not how we coded it.
        if (this.componentTree.isReadOnly) {
          this.faService.globalDisabled = true;
          formGroup.disable();
        }
      }),
    ];

    if (this.componentTree.autosave) {
      this.subscriptions = [
        ...this.subscriptions,
        this.renderedForm.valueChanges
          .pipe(
            // skip the initial form values population
            skip(1),
            debounceTime(this.debounceFormTime),
            tap(() => {
              const status = this.renderedForm.invalid ? 'error' : 'not-saved';
              this.formState.emit(status);
            })
          )
          .subscribe(() => this.submit()),
      ];
    }

    this.subscriptions = [
      ...this.subscriptions,
      this.feService.formExternalEventSubject.subscribe(
        (state: 'save-form' | 'clear-form') => {
          if (state === 'save-form') {
            this.formState.emit(
              this.renderedForm.invalid ? 'error' : 'not-saved'
            );

            if (!this.renderedForm.invalid) {
              this.submit();
            }

            return;
          }

          this.renderedForm.reset();
        }
      ),
      // Emit form status to external service
      this.renderedForm.valueChanges.subscribe(() => {
        this.feService.formEventSubject.next(
          this.feService.getExternalFormStateEvent(this.renderedForm)
        );
      }),
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.faService.publishInitialActions();
  }

  submit() {
    const val = this.renderedForm.getRawValue();
    if (this.renderedForm.invalid) {
      this.formState.emit('error');
      this.fsService.formStateSubject.next('error');

      if (!this.requiredValid) {
        this.submission.emit(val);
      }

      return;
    }

    this.submission.emit(val);

    this.feService.formEventSubject.next({
      eventType: 'edit_submit',
      data: val,
    });

    if (!this.renderedForm.invalid) {
      this.renderedForm.markAsPristine();
    }

    this.feService.formEventSubject.next(
      this.feService.getExternalFormStateEvent(this.renderedForm)
    );
  }

  onResized(event: ResizedEvent) {
    this.feService.formEventSubject.next({
      eventType: 'height',
      data: { height: event.newRect.height },
    });
  }
}
