// Angular
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Models
import { FileUpload } from '@shared/models';

@Component({
  selector: 'app-base64-file-upload',
  templateUrl: './base64-file-upload.component.html',
  styleUrls: ['./base64-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Base64FileUploadComponent),
      multi: true,
    },
  ],
})
export class Base64FileUploadComponent implements ControlValueAccessor {
  disabled: boolean;

  @Input() item: FileUpload;

  // output base64 string
  private _value: string;

  set value(val) {
    this._value = val;
    if (this.onChange) {
      this.onChange(val);
    }
    if (this.onTouched) {
      this.onTouched(val);
    }
  }

  get value() {
    return this._value;
  }

  onTouched: CallableFunction;
  onChange: CallableFunction;

  constructor() {}

  registerOnChange(fn: CallableFunction): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: CallableFunction): void {
    this.onTouched = fn;
  }

  writeValue(val: any): void {
    if (!val) {
      this.value = undefined;
      return;
    }
    this.value = val;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onBasicUpload(event: any): void {
    // as an MVP, only take the first file.
    const reader = new FileReader();
    reader.readAsDataURL(event.files[0]);
    reader.onload = () => {
      // for now, we only support string deserialization
      const result = reader.result;
      if (typeof result === 'string') {
        this.value = result;
      } else {
        this.value = undefined;
      }
    };
  }
}
