// models
import { FormValidatorType } from '@shared/models/validators/form-validator-type.enum';
import { FormValidatorOptions } from '@shared/options';

export class FormValidator {
  type: FormValidatorType;
  customValue: string;
  message: string;
  name: string;

  constructor(options: FormValidatorOptions) {
    this.type = FormValidatorType[options.type];
    this.customValue = options.customValue;
    this.message = options.message;
    this.name = options.name;
  }

  toJSON(): FormValidatorOptions {
    return {
      type: this.type.toString(),
      customValue: this.customValue,
      message: this.message,
      name: this.name,
    };
  }
}
