import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { VertoFormsModule } from 'verto-forms';
import { MahModule } from '../mah/mah.module';
import { FormService } from './form.service';

@Component({
  selector: 'app-slug-based-form',
  template: `
    <div>
      <app-renderer
        #vertoForm
        *ngIf="formJSON; else formIsLoading"
        [inputValues]="inputValues"
        [formJson]="formJSON"
        (submission)="submission.emit($event)"
        (formState)="formState.emit($event)"
        [debounceFormTime]="0"
        [requiredValid]="requiredValid"
      ></app-renderer>

      <ng-template #formIsLoading>
        <mah-spinner *ngIf="!error; else hasError"></mah-spinner>

        <ng-template #hasError> An error occurred. Please try again later. </ng-template>
      </ng-template>
    </div>
  `,
  providers: [FormService],
})
export class SlugBasedFormComponent implements OnInit {
  @Input() formSlug = '';
  @Input() inputValues = {};
  @Input() transformJson = null;
  @Input() requiredValid = true;
  @Output() formState = new EventEmitter<any>();
  @Output() submission = new EventEmitter<any>();
  formJSON: any;
  error = false;

  constructor(private _formService: FormService) {}

  ngOnInit(): void {
    this._loadForm();
  }

  private _loadForm(): void {
    const success = ({ form_templates }) => {
      if (form_templates.length === 0) {
        error('Could not find form template with slug: ' + this.formSlug);
        return;
      }

      if (Object.keys(form_templates[0].form_json).length === 0) {
        error(`Form template ${this.formSlug} does not have a defined form_json`);
        return;
      }

      this.formJSON = form_templates[0].form_json;

      if (this.transformJson) {
        this.transformJson(this.formJSON);
      }

      this.formJSON.autosave = true;
    };

    const error = (message) => {
      console.error(message);
      this.error = true;
    };

    this._formService.getForm(this.formSlug).subscribe(success, ({ message }) => error(message));
  }
}

@NgModule({
  declarations: [SlugBasedFormComponent],
  exports: [SlugBasedFormComponent],
  imports: [CommonModule, VertoFormsModule, MahModule],
})
export class VertoFormsExternalModule {}
