// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

// Components
import { AppComponent, appKey, shellDataToShare } from './app.component';
import { ModuleOutletComponent } from './module-outlet/module-outlet.component';
import { LoadingComponent } from './components/loading.component';

// Directives
import { NamedOutletDirective } from './named-outlet.directive';

// Modules
import { AppRoutingModule } from './app-routing.module';

// engage-utils
import { EngageUtilsModule, MemoryStore } from 'engage-utils';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { GoogleMapsModule } from '@angular/google-maps';
import { APP_KEY, SHELL_DATA } from 'engage-common';

@NgModule({
  declarations: [AppComponent, NamedOutletDirective, ModuleOutletComponent, LoadingComponent],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    EngageUtilsModule.init({
      assetsPath: 'shell/src',
      store: {
        storage: MemoryStore,
        serializer: JSON.stringify,
        deserializer: JSON.parse,
      },
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    OAuthModule.forRoot(),
    GoogleMapsModule,
  ],
  providers: [
    {
      provide: APP_KEY,
      useFactory: () => appKey,
    },
    {
      provide: SHELL_DATA,
      useFactory: () => shellDataToShare,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: Window,
      useValue: window,
    },
    { provide: OAuthStorage, useValue: localStorage },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
