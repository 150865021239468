// models
import { FormComponent } from '@shared/models/form-component';
import { ListFormComponentOptions } from '@shared/options';

export abstract class ListFormComponent extends FormComponent {
  options: any[];
  horizontal: boolean;
  environmentOptions: string;
  hasEnvironmentOptions: boolean;
  remoteSource: string;
  isRemote: boolean;
  bearerTokenKey: string;
  mapFn: string;
  colspanPerItem: number;

  protected constructor(options: ListFormComponentOptions) {
    super(options);
    this.options = options.options || [];
    this.horizontal = options.horizontal;
    this.environmentOptions = options.environmentOptions;
    this.hasEnvironmentOptions = options.hasEnvironmentOptions;
    this.remoteSource = options.remoteSource;
    this.isRemote = options.isRemote;
    this.bearerTokenKey = options.bearerTokenKey;
    this.mapFn = options.mapFn;
    this.colspanPerItem = options.colspanPerItem;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      options: this.isRemote || this.hasEnvironmentOptions ? [] : this.options,
      horizontal: this.horizontal,
      isRemote: this.isRemote,
      bearerTokenKey: this.bearerTokenKey,
      mapFn: this.mapFn,
      remoteSource: this.remoteSource,
      hasEnvironmentOptions: this.hasEnvironmentOptions,
      environmentOptions: this.environmentOptions,
      colspanPerItem: this.colspanPerItem,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputSwitch',
          name: 'horizontal',
          label: 'Horizontal',
          required: false,
          default: false,
        },
        {
          elementType: 'InputSwitch',
          name: 'hasEnvironmentOptions',
          label: 'Environment Options',
          required: false,
          default: false,
          publishActions: [
            {
              condition: 'EQUAL',
              customCondition: true,
              data: '',
              linkName: 'environmentOptionsShow',
              type: '',
            },
            {
              condition: 'EQUAL',
              customCondition: false,
              data: '',
              linkName: 'environmentOptionsHide',
              type: '',
            },
          ],
        },
        {
          elementType: 'InputText',
          name: 'environmentOptions',
          hidden: true,
          label: 'Environment variable',
          subscribeActions: [
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'environmentOptionsShow',
              type: 'TOGGLE_ON',
            },
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'environmentOptionsHide',
              type: 'TOGGLE_OFF',
            },
          ],
        },
        {
          elementType: 'InputSwitch',
          name: 'isRemote',
          label: 'Remote Options',
          required: false,
          default: false,
          publishActions: [
            {
              condition: 'EQUAL',
              customCondition: true,
              data: '',
              linkName: 'remoteSourceShow',
              type: '',
            },
            {
              condition: 'EQUAL',
              customCondition: false,
              data: '',
              linkName: 'remoteSourceHide',
              type: '',
            },
          ],
        },
        {
          elementType: 'InputText',
          name: 'remoteSource',
          hidden: true,
          label: 'Options from remote source',
          subscribeActions: [
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceShow',
              type: 'TOGGLE_ON',
            },
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceHide',
              type: 'TOGGLE_OFF',
            },
          ],
        },
        {
          elementType: 'StaticText',
          name: 'paragraph',
          colspan: 12,
          content: 'Mapping Function:',
          type: 'h4',
        },
        {
          elementType: 'InputTextarea',
          name: 'mapFn',
          hidden: true,
          label: 'dropdown.options = mapFn(apiResult)',
          subscribeActions: [
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceShow',
              type: 'TOGGLE_ON',
            },
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceHide',
              type: 'TOGGLE_OFF',
            },
          ],
        },
        {
          elementType: 'InputText',
          name: 'bearerTokenKey',
          hidden: true,
          label: 'Bearer Token Key for remote source',
          subscribeActions: [
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceShow',
              type: 'TOGGLE_ON',
            },
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceHide',
              type: 'TOGGLE_OFF',
            },
          ],
        },
        {
          elementType: 'InputText',
          name: 'colspanPerItem',
          colspan: 4,
          hidden: false,
          required: false,
          validators: [],
          publishActions: [],
          subscribeActions: [],
          label: 'Col span per item',
          pKeyFilter: 'pint',
          inputType: 'number',
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}

export interface ListOption {
  id: string | number;
  value: any;
  label: string;
  color?: string;
}
