import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Modules
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputMaskModule } from 'primeng/inputmask';
import { EditorModule } from 'primeng/editor';
import { EngageUtilsModule } from 'engage-utils';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { NgHcaptchaModule } from 'ng-hcaptcha';

// Components
import { MahButtonComponent } from './mah-button/mah-button.component';
import { MahSpinnerComponent } from './mah-spinner/mah-spinner.component';
import { MahParagraphComponent } from './mah-paragraph/mah-paragraph.component';
import { MahDropdownComponent } from './mah-dropdown/mah-dropdown.component';
import { MahInputComponent } from './mah-input/mah-input.component';
import { MahInputMaskComponent } from './mah-input-mask/mah-input-mask.component';
import { MahDatePickerComponent } from './mah-datepicker/mah-date-picker.component';
import { MahRadioGroupComponent } from './mah-radio-group/mah-radio-group.component';
import { MahHeadlineComponent } from './mah-headline/mah-headline.component';
import { MahTableComponent } from './mah-table/mah-table.component';
import { MahCardComponent } from './mah-card/mah-card.component';
import { MahCardRowComponent } from './mah-card-row/mah-card-row.component';
import { MahAppointmentCardComponent } from './mah-appointment-card/mah-appointment-card.component';
import { MahCaptchaComponent } from './mah-captcha/mah-captcha.component';
import { MahCheckboxComponent } from './mah-checkbox/mah-checkbox.component';
import { MahTextEditorComponent } from './mah-text-editor/mah-text-editor.component';
import { MahLinkButtonComponent } from './mah-link-button/mah-link-button.component';
import { MahMultiCheckboxComponent } from './mah-multi-checkbox/mah-multi-checkbox.component';
import { MahTextAreaComponent } from './mah-textarea/mah-textarea.component';

@NgModule({
  declarations: [
    MahButtonComponent,
    MahSpinnerComponent,
    MahParagraphComponent,
    MahDropdownComponent,
    MahInputComponent,
    MahInputMaskComponent,
    MahDatePickerComponent,
    MahRadioGroupComponent,
    MahHeadlineComponent,
    MahTableComponent,
    MahCardComponent,
    MahCardRowComponent,
    MahAppointmentCardComponent,
    MahCaptchaComponent,
    MahCheckboxComponent,
    MahTextEditorComponent,
    MahLinkButtonComponent,
    MahMultiCheckboxComponent,
    MahTextAreaComponent,
  ],
  imports: [
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    InputTextModule,
    ProgressSpinnerModule,
    CheckboxModule,
    ToastModule,
    FormsModule,
    CommonModule,
    EngageUtilsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    EditorModule,
    NgHcaptchaModule.forRoot(),
    InputMaskModule,
  ],
  exports: [
    MahButtonComponent,
    MahSpinnerComponent,
    MahParagraphComponent,
    MahDropdownComponent,
    MahInputComponent,
    MahInputMaskComponent,
    MahDatePickerComponent,
    MahRadioGroupComponent,
    MahHeadlineComponent,
    MahTableComponent,
    MahCardComponent,
    MahCardRowComponent,
    MahCaptchaComponent,
    MahTextEditorComponent,
    MahAppointmentCardComponent,
    MahCheckboxComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgHcaptchaModule,
    MahLinkButtonComponent,
    MahMultiCheckboxComponent,
    MahTextAreaComponent,
  ],
})
export class MahModule {}
