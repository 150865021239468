// Angular
import { Component } from '@angular/core';

// Component
import { ListFormComponentComponent } from '@shared/components/list-form-component/list-form-component.component';

// Models
import { Dropdown } from '@shared/models/dropdown';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent extends ListFormComponentComponent {
  item: Dropdown;
}
