import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mood-button',
  templateUrl: './mood-button.component.html',
  styleUrls: ['./mood-button.component.scss'],
})
export class MoodButtonComponent {
  @Input() buttonClasses: string[] = ['mood-primary'];
  @Input() disabled: boolean | (() => boolean) = false;

  @Output() onClick = new EventEmitter();

  get isDisabled() {
    if (typeof this.disabled === 'function') {
      return this.disabled();
    }

    return this.disabled;
  }
}
