// angular
import { NgModule } from '@angular/core';

// module components
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { BuilderComponent } from './builder/builder.component';
import { EditComponentComponent } from './edit-component/edit-component.component';
import { IframeComponent } from './iframe/iframe.component';
import { RendererPageComponent } from './renderer-page/renderer-page.component';
import { RendererComponent } from './renderer/renderer.component';
import { ToolbarComponent, ToolbarModule } from './toolbar/toolbar.component';
import { CommonModule } from '@angular/common';
import { ResizedDirective } from './renderer/angular-resize-event/resize.directive';

@NgModule({
  declarations: [
    BuilderComponent,
    EditComponentComponent,
    RendererComponent,
    RendererPageComponent,
    IframeComponent,
    ResizedDirective,
  ],
  imports: [CommonModule, SharedModule, HttpClientModule, ToolbarModule],
  exports: [
    ToolbarModule,
    BuilderComponent,
    EditComponentComponent,
    RendererComponent,
    ResizedDirective,
    RendererPageComponent,
    IframeComponent,
    CommonModule,
    SharedModule,
    HttpClientModule,
  ],
})
export class AppLibraryModule {}
