// angular
import { AbstractControl, UntypedFormControl } from '@angular/forms';

// models
import { FormAction } from '@root/models/form-action';
import { FormActionType } from '@root/models/form-action-type.enum';
import { ValidatorFactory } from '@root/models/validator-factory';
import { FormComponentOptions } from '@shared/options';
import { FormElementImpl } from './form-element-impl';

/**
 * Form components like input text-layout, text-layout areas
 */
export abstract class FormComponent extends FormElementImpl {
  label: string;
  readonly: boolean;

  requiredErrorText: string;

  constructor(options?: FormComponentOptions) {
    super(options);
    this.label = options.label;
    this.readonly = options.readonly;
    this.requiredErrorText = options.requiredErrorText;
  }

  getContainerIds(): string[] {
    return [];
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      label: this.label,
      readonly: this.readonly,
      requiredErrorText: this.requiredErrorText,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'label',
          label: 'Label',
          required: true,
        },
        {
          elementType: 'InputText',
          name: 'requiredErrorText',
          label: 'Custom Required Error Message',
          required: false,
        },
        {
          elementType: 'InputSwitch',
          name: 'readonly',
          label: 'Read Only',
          required: false,
          default: false,
        },
        {
          elementType: 'InputText',
          name: 'default',
          label: 'Default Value',
          required: false,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }

  /**
   * Handles form actions (pub-sub) related specifically to FormComponent.
   * @param action is action to run
   * @param globalDisabled: true if the form is disabled globally
   */
  handleAction(action: FormAction, globalDisabled: boolean): void {
    // call parent
    super.handleAction(action, globalDisabled);
    // component only action types
    switch (action.type) {
      case FormActionType.READONLY_TRUE:
        this.formControl.disable();
        break;
      case FormActionType.READONLY_FALSE:
        if (!globalDisabled) {
          this.formControl.enable();
        }
        break;
    }
  }

  toFormControl(value?: any): AbstractControl {
    this.formControl = new UntypedFormControl(
      { value: value || this.default, disabled: this.readonly },
      {
        validators: ValidatorFactory.generateValidators(this),
        updateOn: 'change',
      }
    );
    return this.formControl;
  }
}
