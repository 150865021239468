// Angular
import { Component } from '@angular/core';

// Components
import { FormContainerComponent } from '@shared/components/form-container/form-container.component';

// Models
import { CarechartAccordion } from '@shared/models';

@Component({
  selector: 'app-carechart-accordion',
  templateUrl: './carechart-accordion.component.html',
  styleUrls: ['./carechart-accordion.component.scss'],
})
export class CarechartAccordionComponent extends FormContainerComponent {
  item: CarechartAccordion;
}
