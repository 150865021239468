import { Component, Input } from '@angular/core';
import { valOrEval } from '../mood-utils/val-or-eval';

@Component({
  selector: 'mood-header',
  templateUrl: './mood-header.component.html',
  styleUrls: ['./mood-header.component.scss'],
})
export class MoodHeaderComponent {
  @Input() label: string | (() => string);

  get headerLabel(): string {
    return valOrEval(this.label);
  }
}
