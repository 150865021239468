// angular
import { AbstractControl, UntypedFormControl } from '@angular/forms';

// models
import { FormContainer } from '@shared/models/form-container';
import { RepeatableContainerOptions } from '@shared/options';

export class RepeatableContainer extends FormContainer {
  elementTypeName: string = 'RepeatableContainer';

  addButtonLabel: string;
  maxItems: number;
  minItems: number;
  showIndex: boolean;
  getValue: any;

  constructor(options: RepeatableContainerOptions) {
    super(options);
    this.showIndex = options.showIndex;
    this.addButtonLabel = options.addButtonLabel;
    this.maxItems = options.maxItems == null ? 100 : options.maxItems;
    this.minItems = options.minItems == null ? 1 : options.minItems;
  }

  toJSON(): object {
    return Object.assign(super.toJSON(), {
      addButtonLabel: this.addButtonLabel,
      maxItems: this.maxItems,
      minItems: this.minItems,
      showIndex: this.showIndex,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'addButtonLabel',
          label: 'Add New Button Label',
          required: true,
        },
        {
          elementType: 'InputText',
          name: 'minItems',
          label: 'Min Items',
          inputType: 'number',
          required: true,
        },
        {
          elementType: 'InputText',
          name: 'maxItems',
          label: 'Max Items',
          inputType: 'number',
          required: true,
        },
        {
          elementType: 'InputSwitch',
          name: 'showIndex',
          label: 'Show Index',
          required: false,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }

  toFormControl(): AbstractControl {
    this.formControl = new UntypedFormControl('');
    return this.formControl;
  }
}
