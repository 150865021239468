import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormData } from 'engage-common';
import { UntypedFormGroup } from '@angular/forms';
import { valOrEval } from '../mood-utils/val-or-eval';

@Component({
  selector: 'mood-input',
  templateUrl: './mood-input.component.html',
  styleUrls: ['./mood-input.component.scss'],
})
export class MoodInputComponent {
  @Input() label: string;
  @Input() disabled: boolean | (() => boolean) = false;
  @Input() placeholder: string = '';
  @Input() autocomplete: string = 'on';
  @Input() value: string = '';
  @Input() error: string = '';
  @Input() mask: string = '';
  @Input() helpText: string = '';
  @Input() form: UntypedFormGroup;
  @Input() formData: FormData;

  @Output() onInput = new EventEmitter();

  constructor(private _cdr: ChangeDetectorRef) {}

  get inputHelpText(): string {
    return valOrEval(this.helpText);
  }

  valueChange($event: any): void {
    this.formData.currentValue = $event.target.value;
  }

  get inputPlaceHolder(): string {
    if (this.form) {
      return valOrEval(this.formData.placeholder);
    }

    return valOrEval(this.placeholder);
  }

  get inputLabel(): string {
    return valOrEval(this.label);
  }
}
