import { Component } from '@angular/core';

@Component({
  selector: 'app-stub',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  // This component only exists to allow page refresh. Shell initialization will cause the page to
  // redirect to the correct micro-frontend.
}
