import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormData } from 'engage-common';

@Component({
  selector: 'mood-multi-checkbox',
  templateUrl: './mood-multi-checkbox.component.html',
  styleUrls: ['./mood-multi-checkbox.component.scss'],
})
export class MoodMultiCheckboxComponent {
  @Input() form: UntypedFormGroup;
  @Input() formData: FormData;
  @Output() onChange = new EventEmitter();

  change(event, option) {
    this.formData.currentValue = this.form.controls[this.formData.controlName].value;
    if (!!this.formData.hasGroups && !!event.checked) {
      this.unselectGroups(option);
    }
    this.onChange.emit({ event, option, currentSelectedValues: this.formData.currentValue });
  }

  active(optionValue): boolean {
    return this.form.controls[this.formData.controlName].value.includes(optionValue);
  }

  /**
   * Unselects checkboxes that do not belong to the group that was just selected
   * Example: COVID-19 Survey Q1: If user selects 'No Symptoms' option, all the others are unselected
   */
  unselectGroups(option) {
    const { value: selectedValues } = this.form.controls[this.formData.controlName];
    let updatedValues = selectedValues.filter((selectedValue) => {
      return this.formData.options.find(({ group, value }) => {
        return value == selectedValue && group === option.group;
      });
    });
    this.form.controls[this.formData.controlName].setValue(updatedValues);
    this.formData.currentValue = updatedValues;
  }
}
