// models
import { ListFormComponent } from '@shared/models/list-form-component';
import { CheckboxOptions } from '@shared/options';

export class Checkbox extends ListFormComponent {
  // https://www.primefaces.org/primeng/showcase/#/checkbox
  elementTypeName: string = 'Checkbox';

  options: Array<{ label: string; value: any }>;

  binary: boolean;

  constructor(options: CheckboxOptions) {
    super(options);
    this.binary = options.binary;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      binary: this.binary,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputSwitch',
          name: 'binary',
          label: 'Binary checkbox (only 1 value)',
          required: false,
          default: false,
        },
        {
          elementType: 'RepeatableContainer',
          name: 'options',
          colspan: 12,
          label: 'Options',
          addButtonLabel: 'Add New Option',
          children: [
            {
              elementType: 'InputText',
              name: 'label',
              label: 'Label',
              pKeyFilter: 'alphanum',
              required: true,
              colspan: 4,
            },
            {
              elementType: 'InputText',
              name: 'value',
              label: 'Value',
              pKeyFilter: 'alphanum',
              required: true,
              colspan: 4,
            },
          ],
          subscribeActions: [
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceShow',
              type: 'TOGGLE_OFF',
            },
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceHide',
              type: 'TOGGLE_ON',
            },
          ],
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
