class _MemoryStore implements Storage {
  private _store = {};

  [name: string]: any;

  length: number = 0;

  clear(): void {
    this._store = {};
    this.length = 0;
  }

  getItem(key: string): string | null {
    return this._store[key];
  }

  key(index: number): string | null {
    // Not implemented
    return;
  }

  removeItem(key: string): void {
    if (this._store[key] !== undefined) {
      this.length--;
    }

    delete this._store[key];
  }

  setItem(key: string, value: string): void {
    if (this._store[key] !== undefined) {
      this.length++;
    }
    this._store[key] = value;
  }
}

export const MemoryStore = new _MemoryStore();
