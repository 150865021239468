<div class="p-fluid" [attr.data-name]="item.name">
  <span class="p-label">
    <label [htmlFor]="item.id">{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label>
    <textarea
      pInputTextarea
      [id]="item.id"
      [formControl]="item.formControl"
      [cols]="item.cols"
      [rows]="item.rows"
      [placeholder]="item.placeholder"
      [autoResize]="item.autoResize"
      [maxlength]="item.maxlength"
      [class.not-resizable]="!item.resizable"
      (input)="emitEvent()"
    ></textarea>
  </span>
  <app-form-validators [item]="item"></app-form-validators>
</div>
