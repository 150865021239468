<ng-container *ngIf="!form; else withinForm">
  <label
    class="container-radio"
    tabindex="0"
    (keydown.enter)="radio.click()"
    [ngClass]="{ active: radio.checked }"
    *ngFor="let radioButton of radioButtons"
  >
    {{ radioLabel(radioButton) }}
    <input
      type="checkbox"
      (click)="handleClick($event, radioButton)"
      [value]="radioButton.value"
      tabindex="-1"
      #radio
    />
    <span class="checkmark"></span>
  </label>
</ng-container>

<ng-template #withinForm>
  <div [formGroup]="form">
    <label
      class="container-radio"
      tabindex="0"
      (keydown.enter)="radio.click()"
      [ngClass]="{ active: formRadio.value === form.controls[formData.controlName].value }"
      *ngFor="let formRadio of formData.options; let i = index"
    >
      {{ radioLabel(formRadio) }}
      <input
        tabindex="-1"
        #radio
        [formControlName]="formData.controlName"
        [type]="formData.controlType"
        [name]="formData.controlName"
        [value]="formRadio.value"
        [checked]="formRadio.value === form.controls[formData.controlName].value"
        (click)="handleClick($event, formRadio)"
        [required]="formData.validators.required"
        [pattern]="formData.validators.pattern"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</ng-template>
