// angular
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// rxjs
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormEventService {
  formEventSubject: Subject<{ eventType: string; data: object }>;
  public formExternalEventSubject: Subject<string>;

  public height: number;

  constructor() {
    this.formEventSubject = new BehaviorSubject(null);
    this.formExternalEventSubject = new BehaviorSubject(null);
  }

  getFormEvents(): Observable<{ eventType: string; data: object }> {
    return this.formEventSubject.pipe(filter((val) => !!val));
  }

  getExternalFormStateEvent(formGroup: UntypedFormGroup): {
    eventType: string;
    data: object;
  } {
    return {
      eventType: 'form_status',
      data: {
        valid: formGroup.valid,
        dirty: formGroup.dirty,
        height: this.height,
      },
    };
  }
}
